import React from 'react'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'

/**
 * A button styled for deleting items. It will show a confirmation dialog before
 * deleting if `showConfirm` is true.
 *
 * @param {object} props
 * @param {Function} props.onClick The function to call when the button is
 * clicked (or when the confirmation dialog is accepted)
 * @param {boolean} props.isLoading Whether the button is in a loading state
 * @param {boolean} [props.showConfirm=true] Whether to show a confirmation
 * dialog before deleting
 * @param {string} [props.confirmMessage='Are you sure you want to delete
 * this?'] The message to show in the confirmation dialog
 * @return {*}
 */
function DeleteButton({
  onClick, isLoading, showConfirm = true, confirmMessage = 'Are you sure you want to delete this?',
}) {
  const confirm = () => {
    confirmDialog({
      message: confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: onClick,
    })
  }

  return (
    <Button
      className="p-button-text text-sm"
      severity="danger"
      loading={isLoading}
      label="Delete"
      onClick={showConfirm ? confirm : onClick}
    />
  )
}

export default DeleteButton
