import React, { useState, useEffect, useRef } from 'react'
import {
  compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { ProfileForm, SettingsForm } from '@components/pharmacists/PharmacistForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentUser } from '@components/App'
import DeactivateUserDialog from '@components/users/DeactivateUserDialog'
import ReactivateUserDialog from '@components/users/ReactivateUserDialog'
import { useDeactivatePharmacist, useReactivatePharmacist } from '@hooks/pharmacists'
import {
  usePharmacistUpdateMutation,
} from './pharmacistsHooks'

function buildMenuItems({
  pharmacist,
  isCurrentUserSuperAdmin,
  handleDeactivateUser,
  handleReactivateUser,
}) {
  const items = []

  if (!pharmacist || !isCurrentUserSuperAdmin) {
    return items
  }

  if (pharmacist?.isActive) {
    items.push({
      label: 'Deactivate',
      icon: 'pi pi-times',
      command: () => handleDeactivateUser(),
    })
  } else {
    items.push({
      label: 'Reactivate',
      icon: 'pi pi-check',
      command: () => handleReactivateUser(),
    })
  }

  return items
}

function UpdatePharmacist({ pharmacist, handleSetHeader }) {
  const statusMessageRef = useRef(null)
  const pharmacistActionMenuRef = useRef(null)
  const { pharmacistId } = useParams()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const [profile, setProfile] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })
  const [settings, setSettings] = useState({ inventoryAlertsEnabled: false })
  const [deactivateDialogVisible, setDeactivateDialogVisible] = useState(false)
  const [reactivateDialogVisible, setReactivateDialogVisible] = useState(false)

  const rootPath = '/admin/pharmacists'
  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const isCurrentUserSuperAdmin = currentUser.role === 'superadmin'

  const {
    mutateAsync: updatePharmacist,
    isLoading: updateIsLoading,
  } = usePharmacistUpdateMutation(pharmacistId, statusMessageRef)

  const {
    mutateAsync: deactivatePharmacist,
    isLoading: isDeactivatePharmacistLoading,
  } = useDeactivatePharmacist(statusMessageRef)

  const {
    mutateAsync: reactivatePharmacist,
    isLoading: isReactivatePharmacistLoading,
  } = useReactivatePharmacist(statusMessageRef)

  const handleUpdateProfile = () => {
    updatePharmacist({ ...profile }, {
      onSuccess: () => {
        statusMessageRef.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Profile updated',
        })
      },
    })
  }

  const handleUpdateSettings = (newSettings) => {
    updatePharmacist({ ...newSettings }, {
      onSuccess: () => {
        statusMessageRef.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Settings updated',
        })
      },
    })
  }

  const handleDeactivate = async (note) => {
    await deactivatePharmacist({ pharmacistId, note })
    navigate(rootPath)
  }

  const handleReactivate = async ({ userId, siteIds, note }) => {
    await reactivatePharmacist({ userId, siteIds, note })

    setReactivateDialogVisible(false)
    navigate(rootPath)
  }

  const breadcrumbs = isCurrentUserAdmin ? compact([
    {
      label: 'Pharmacist',
      template: <Link to={`/admin/pharmacists/${pharmacistId}`}>Pharmacist</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ]) : []

  const menuItems = buildMenuItems({
    pharmacist,
    isCurrentUserSuperAdmin,
    handleDeactivateUser: () => setDeactivateDialogVisible(true),
    handleReactivateUser: () => setReactivateDialogVisible(true),
  })

  const primaryAction = menuItems.length !== 0 ? (
    <>
      <Menu
        model={menuItems}
        popup
        ref={pharmacistActionMenuRef}
        id="pharmacistActionMenu"
      />
      <Button
        label="Actions"
        className="p-button-sm"
        icon="pi pi-chevron-down"
        onClick={(event) => pharmacistActionMenuRef.current.toggle(event)}
      />
    </>
  ) : null

  useEffect(() => {
    if (pharmacist) {
      setProfile({
        email: pharmacist.email,
        firstName: pharmacist.firstName,
        lastName: pharmacist.lastName,
      })
      setSettings({
        inventoryAlertsEnabled: pharmacist.inventoryAlertsEnabled,
        trackableActivitiesEmailEnabled: pharmacist.trackableActivitiesEmailEnabled,
      })
    }

    handleSetHeader({ breadcrumbs, primaryAction })
  }, [pharmacist])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessageRef} />
      <DeactivateUserDialog
        visible={deactivateDialogVisible}
        onHide={() => setDeactivateDialogVisible(false)}
        onConfirm={handleDeactivate}
        isLoading={isDeactivatePharmacistLoading}
      />
      <ReactivateUserDialog
        statusMessageRef={statusMessageRef}
        userRole="pharmacist"
        userId={pharmacistId}
        visible={reactivateDialogVisible}
        onHide={() => setReactivateDialogVisible(false)}
        onConfirm={handleReactivate}
        isLoading={isReactivatePharmacistLoading}
      />
      <div className="xl:col-6 col-12">
        <FormWrapper title="Profile" fullHeight={false}>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Save Profile"
            className="p-button-sm mt-4"
            disabled={isEqual(profile, pick(pharmacist, keys(profile)))}
            loading={updateIsLoading}
            onClick={handleUpdateProfile}
          />
        </FormWrapper>
      </div>
      <div className="xl:col-6 col-12 flex flex-column gap-3">
        <FormWrapper title="Settings">
          <SettingsForm
            settings={settings}
            setSettings={
            (value) => {
              handleUpdateSettings(value)
            }
          }
            loading={updateIsLoading}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default UpdatePharmacist
