import React from 'react'

function HistoryMarker({ color }) {
  return (
    <span
      className="w-1rem h-1rem border-circle border-solid"
      style={{
        borderColor: color,
      }}
    />
  )
}

export default HistoryMarker
