import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useSites = (organizationId, statusMessage, enabled = true) => {
  const query = useQuery({
    queryKey: ['sites', organizationId],
    queryFn: () => ApiClient.get(`/sites?organization_id=${organizationId}`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    enabled,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.sites'), (site) => (site)),
  }), [query.data])
}

export default useSites
