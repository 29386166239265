import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'
import { useCurrentUser } from '@components/App'
import PharmacistDashboard from '@components/pharmacists/PharmacistDashboard'
import { usePharmacistQuery } from './pharmacistsHooks'
import UpdatePharmacist from './UpdatePharmacist'

function Pharmacist({ usePharmacistsHeader }) {
  const { pharmacistId } = useParams()
  const { maxDataViewHeight, setHeader } = usePharmacistsHeader()
  const { data: pharmacist, isLoading } = usePharmacistQuery(pharmacistId)
  const currentUser = useCurrentUser()

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const title = {
    label: (
      <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
        <span className="flex flex-row align-items-center" id="pharmacist-name-tab">
          <i className="text-xl pi pi-user mr-2" />
          {isLoading && (
            <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
          )}
          {!isLoading && (
            <>
              <span className="fadein">
                {`${pharmacist.firstName} ${pharmacist.lastName}`}
              </span>
              {!pharmacist?.isActive && (
                <Tag className="ml-2" value="Deactivated" severity="danger" />
              )}
            </>
          )}
        </span>
      </div>
    ),
  }

  const tabs = []

  const breadcrumbs = [
    {
      label: 'Pharmacists',
      template: <span>Pharmacist</span>,
    },
  ]

  const handleSetHeader = (props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }

  return (
    <Routes>
      <Route
        index
        element={(
          <PharmacistDashboard
            maxDataViewHeight={maxDataViewHeight}
            isCurrentUserAdmin={isCurrentUserAdmin}
            pharmacistId={pharmacistId}
            handleSetHeader={handleSetHeader}
            pharmacistLoading={isLoading}
            pharmacist={pharmacist}
          />
        )}
      />
      <Route
        path="update"
        element={<UpdatePharmacist pharmacist={pharmacist} handleSetHeader={handleSetHeader} />}
      />
    </Routes>
  )
}

export default Pharmacist
