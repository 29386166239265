import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import MatchLabel from '@components/misc/MatchLabel'
import { useOnboardPendingUser } from '@hooks/pendingUsers'
import { useIgnorePendingUser } from './patientHooks'

const fieldTemplate = (patientValue, pendingUserValue, displayValue) => (
  <MatchLabel
    referenceValue={patientValue}
    comparedValue={pendingUserValue}
    displayValue={displayValue}
  />
)

const actionsTemplate = (handleOnboard, handleIgnore, pendingUser) => {
  const onConfirmMatch = () => {
    confirmDialog({
      message: 'Are you sure you want to confirm this match?',
      header: 'Confirm Match',
      accept: () => handleOnboard(pendingUser),
    })
  }

  const onIgnoreMatch = () => {
    confirmDialog({
      message: 'Are you sure you want to ignore this potential match?',
      header: 'Confirm Ignore',
      accept: () => handleIgnore(pendingUser),
    })
  }

  return (
    <span className="flex justify-content-end">
      <Button
        label="Confirm Match"
        className="p-button-sm p-button-success mr-3"
        icon="pi pi-user-plus"
        onClick={onConfirmMatch}
      />
      <Button
        label="Ignore"
        className="p-button-sm p-button-secondary p-button-outlined"
        icon="pi pi-times"
        onClick={onIgnoreMatch}
      />
    </span>
  )
}

function MatchingUsers({ patient, handleSetHeader }) {
  const { patientId } = useParams()
  const organization = useCurrentOrganization()
  const statusMessage = useRef()
  const [matchingUsers, setMatchingUsers] = useState([])

  useEffect(() => {
    handleSetHeader({ primaryAction: null, notifications: [] })
  }, [patient])

  useEffect(() => {
    if (patient) {
      setMatchingUsers(patient.matchingUsers)
    }
  }, [patient])

  const { mutateAsync: onboardPendingUser } = useOnboardPendingUser(statusMessage)

  const handleOnboard = async (pendingUser) => {
    await onboardPendingUser({
      id: pendingUser.id,
      patientId,
    }, {
      onSuccess: () => {
        window.location.href = `/admin/patients/${patientId}/orders/needs-review`
      },
    })
  }

  const { mutateAsync: ignorePendingUser } = useIgnorePendingUser(statusMessage, patientId)

  const handleIgnore = async (pendingUser) => {
    await ignorePendingUser({ pendingUser })
  }

  const patientLabel = organization.patientLabelSingular.toLowerCase()
  let heading = `The following pending user is a potential match for this ${patientLabel}:`
  if (matchingUsers.length > 1) {
    heading = `The following pending users are potential matches for this ${patientLabel}:`
  }

  return (
    <Card className="h-full">
      <Messages ref={statusMessage} />
      <div className="mb-3">
        <span>{heading}</span>
      </div>
      <DataTable dataKey="id" value={matchingUsers}>
        <Column
          header="First Name"
          body={(user) => fieldTemplate(patient.firstName, user.firstName, user.firstName)}
        />
        <Column
          header="Last Name"
          body={(user) => fieldTemplate(patient.lastName, user.lastName, user.lastName)}
        />
        <Column
          header="Date of Birth"
          body={(user) => fieldTemplate(patient.dateOfBirth, user.dateOfBirth, user.dateOfBirth)}
        />
        <Column
          header="Birth Sex"
          body={(user) => fieldTemplate(patient.birthSex, user.birthSex, user.birthSex)}
        />
        <Column
          header="Site"
          field="site"
        />
        <Column
          header="Pharmacy Name"
          field="pharmacyName"
        />
        <Column
          body={(user) => actionsTemplate(handleOnboard, handleIgnore, user)}
        />
      </DataTable>
    </Card>
  )
}

export default MatchingUsers
