import React, {
  memo, useEffect, useRef, useState,
} from 'react'
import DateTable from '@components/display/DateTable/DateTable'
import { OverlayPanel } from 'primereact/overlaypanel'
import { get, isEmpty, startCase } from 'lodash'
import { useVitalsDashboard } from '@hooks/dashboards'
import {
  groupVitalsByStatus,
  formatDashboardItems,
  vitalsDashboardColumns,
  vitalValueDisplay,
} from './VitalDashboardUtils'
import StatusOverlay from './StatusOverlay'
import CompletedTag from './StatusTag/CompletedTag'
import SkippedTag from './StatusTag/SkippedTag'
import MissedTag from './StatusTag/MissedTag'

const VitalsDashboard = memo(({
  dateRange, patient,
}) => {
  const statusOverlay = useRef()
  const [selectedVitals, setSelectedVitals] = useState()
  const [formattedDashboardItems, setFormattedDashboardItems] = useState()
  const statusMessagesRef = useRef()
  const {
    data: vitalsDashboard,
    isLoading,
  } = useVitalsDashboard(patient.id, dateRange, statusMessagesRef)

  useEffect(() => {
    setFormattedDashboardItems(
      formatDashboardItems(vitalsDashboard?.vitalsDashboardItems, patient.timezone),
    )
  }, [vitalsDashboard, patient.timezone])

  const onSelectVitals = (e, vitals) => {
    setSelectedVitals(vitals)
    if (statusOverlay.current) {
      statusOverlay.current.hide()
      requestAnimationFrame(() => {
        statusOverlay.current.show(e)
      })
    } else {
      statusOverlay.current.show(e)
    }
  }

  const measurementTypeTemplate = (rowData) => (
    <div className="flex flex-column gap-1 align-items-start text-left">
      <span>{startCase(rowData.measurementType)}</span>
    </div>
  )

  const conditionCheckTemplate = (rowData, column) => {
    const conditionChecks = get(rowData, `conditionChecks.${column.field}`)
    const adhocMeasurements = get(rowData, `adhocMeasurements.${column.field}`)
    if (isEmpty(conditionChecks) && isEmpty(adhocMeasurements)) return null

    const vitalsByStatus = groupVitalsByStatus(conditionChecks, adhocMeasurements)
    return (
      <div className="flex flex-column gap-1 align-items-center justify-content-center">
        {vitalsByStatus.completed?.map((vital) => (
          <CompletedTag
            key={vital.id}
            onClick={(e) => onSelectVitals(e, [vital])}
            value={vitalValueDisplay(vital)}
          />
        ))}
        {
          vitalsByStatus.skipped?.length > 0 && (
            <SkippedTag
              skipCount={vitalsByStatus.skipped.length}
              onClick={(e) => onSelectVitals(e, vitalsByStatus.skipped)}
            />
          )
        }
        {
          vitalsByStatus.missed?.length > 0 && (
            <MissedTag
              missedCount={vitalsByStatus.missed.length}
              onClick={(e) => onSelectVitals(e, vitalsByStatus.missed)}
              className="bg-red-500"
            />
          )
        }
      </div>
    )
  }

  return (
    <div>
      <OverlayPanel ref={statusOverlay} className="adherence-status-overlay">
        <StatusOverlay
          vitals={selectedVitals}
          timezone={patient.timezone}
        />
      </OverlayPanel>
      <DateTable
        columns={vitalsDashboardColumns({
          dateRange,
          measurementTypeTemplate,
          conditionCheckTemplate,
        })}
        data={formattedDashboardItems}
        isLoading={isLoading}
        tableClassName="adherence-table"
        emptyMessage="No vitals available"
        className="schedule-mar-view"
        // disabling row grouping temporarily to find solution for primereact scroll bug
        // rowGroupMode="rowspan"
        // groupRowsBy={['treatment.id', 'id']}
        sortMode="single"
        scrollHeight="70vh"
      />
    </div>
  )
})

VitalsDashboard.displayName = 'VitalsDashboard'

export default VitalsDashboard
