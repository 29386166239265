import React from 'react'
import { useConsumptionHistory } from '@hooks/consumptions'
import StatusOverlayContentHeader from './StatusOverlayContentHeader'
import StatusOverlayContentDetails from './StatusOverlayContentDetails'
import ConsumptionHistory from './ConsumptionHistory/ConsumptionHistory'
import { timelineItemsFromConsumption } from './ConsumptionHistory/utils'

function StatusOverlayContent({ consumption, handleEdit }) {
  const {
    data: consumptionWithHistory,
    isLoading,
  } = useConsumptionHistory({ consumption })

  if (isLoading) {
    return null
  }

  return (
    <div className="flex flex-column p-1">
      <StatusOverlayContentHeader consumption={consumptionWithHistory} handleEdit={handleEdit} />
      <StatusOverlayContentDetails {...consumptionWithHistory} />
      <ConsumptionHistory
        timelineItems={timelineItemsFromConsumption(consumptionWithHistory)}
      />
    </div>
  )
}

export default React.memo(StatusOverlayContent)
