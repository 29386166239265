import React from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useDeleteSmartFlip } from '@hooks/smartFlips'

function DeleteSmartFlipDialog({
  smartFlip, isVisible, onHide, messagesRef,
}) {
  const {
    mutateAsync: deleteSmartFlip,
    isLoading,
  } = useDeleteSmartFlip(messagesRef)

  if (!smartFlip) {
    return null
  }

  const onDelete = async () => {
    await deleteSmartFlip(smartFlip.id)
    onHide()
  }

  return (
    <Dialog
      header="Delete Smart Flip"
      visible={isVisible}
      onHide={onHide}
      style={{ width: '50vw' }}
      dismissableMask
    >
      <div className="flex flex-column gap-1">
        <div className="flex flex-row gap-2 align-items-center">
          <i className="pi pi-exclamation-triangle text-orange-500" style={{ fontSize: '2rem' }} />
          <span className="text-lg">
            Are you sure you want to delete this smart flip?
          </span>
        </div>
        <div className="flex flex-row gap-2 justify-content-end">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={onHide}
            disabled={isLoading}
          />
          <Button
            label="Delete"
            onClick={onDelete}
            loading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteSmartFlipDialog
