import React, { useState } from 'react'
import { Button } from 'primereact/button'
import MonthlyExportDialog from '@components/patients/MonthlyExportDialog'
import { useCreateReportMar } from '@hooks/reports'

export default function PrintButton({ patientId }) {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <MonthlyExportDialog
        dialogTitle="MAR"
        exportMutation={useCreateReportMar}
        isVisible={isVisible}
        patientId={patientId}
        setIsVisible={setIsVisible}
        reportType="mar"
        showBlankReportSwitch
      />
      <Button label="Print" onClick={() => setIsVisible(true)} />
    </>
  )
}
