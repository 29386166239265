import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useAllSites(enabled = true) {
  return useQuery({
    queryKey: ['allSites'],
    queryFn: () => ApiClient.get('/sites/all'),
    enabled,
    placeholderData: [],
    select: (data) => data?.sites || [],
  })
}

export default useAllSites
