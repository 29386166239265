import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Messages } from 'primereact/messages'
import UserProfileCard from '@components/user/UserProfileCard'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { useAllSites } from '@hooks/sites'
import PharmacistSites from './PharmacistSites'
import { usePharmacistUpdateMutation } from '../../features/Pharmacists/pharmacistsHooks'

function SiteAccess({
  pharmacistId,
  pharmacist,
  editEnabled = true,
  statusMessage,
  isLoading,
  maxDataViewHeight,
}) {
  const {
    data: availableSites,
    isLoading: isLoadingSites,
  } = useAllSites(editEnabled)

  const {
    mutateAsync: updatePharmacist,
    isLoading: isUpdatingPharmacist,
  } = usePharmacistUpdateMutation(pharmacistId, statusMessage)

  const handlePharmacistSitesChange = (sites) => {
    updatePharmacist({
      siteIds: sites.map((site) => site.id),
    })
  }

  if (isLoading) {
    return (
      <div className="col-12">
        <Skeleton className="inline-flex" width="5rem" height="1rem" />
      </div>
    )
  }

  return (
    <PharmacistSites
      availableSites={availableSites}
      userSites={pharmacist.sites}
      onUserSitesChange={(sites) => handlePharmacistSitesChange(sites)}
      isLoading={isLoadingSites || isUpdatingPharmacist}
      maxDataViewHeight={maxDataViewHeight}
      editEnabled={editEnabled}
    />
  )
}

function PharmacistDashboard({
  pharmacistId,
  pharmacist,
  pharmacistLoading,
  handleSetHeader,
  isCurrentUserAdmin,
  maxDataViewHeight,
}) {
  const statusMessage = useRef()
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()

  const primaryAction = (isCurrentUserAdmin || currentUser.id === Number(pharmacistId)) && (
    <div className="flex flex-row gap-2">
      <Link to={`/admin/pharmacists/${pharmacistId}/update`}>
        <Button className="p-button-sm" label="Edit Pharmacist" />
      </Link>
    </div>
  )

  useEffect(() => handleSetHeader({ primaryAction }), [pharmacist])

  return (
    <div className="flex flex-row flex-wrap">
      <Messages ref={statusMessage} />
      <div className="col-6">
        <UserProfileCard
          isLoading={pharmacistLoading}
          user={pharmacist}
        />
      </div>
      <div className="col-12">
        <SiteAccess
          pharmacistId={pharmacistId}
          pharmacist={pharmacist}
          isLoading={pharmacistLoading}
          organization={organization}
          statusMessage={statusMessage}
          maxDataViewHeight={maxDataViewHeight}
          editEnabled={isCurrentUserAdmin}
        />
      </div>
    </div>
  )
}

export default PharmacistDashboard
