import React from 'react'
import { Button } from 'primereact/button'

import { momentFormats } from '@services/utils/moment'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'

function GranularityRangeView({
  startDate, endDate, onPrevious, onNext, isNextDisabled,
}) {
  const dateRangeTemplate = () => {
    const startDateFormatted = startDate.format(momentFormats.dateYear)
    const endDateFormatted = endDate.format(momentFormats.dateYear)
    if (startDateFormatted === endDateFormatted) {
      return startDateFormatted
    }
    return `${startDateFormatted} - ${endDateFormatted}`
  }

  return (
    <div>
      <div className="flex flex-row gap-2 align-items-center">
        <Button icon="pi pi-chevron-left" className="p-button-sm p-button-text" onClick={onPrevious} id="previous-date" />
        <div>{dateRangeTemplate()}</div>
        <Button icon="pi pi-chevron-right" className="p-button-sm p-button-text" onClick={onNext} disabled={isNextDisabled} id="next-date" />
      </div>
    </div>
  )
}

function CustomDateRangeView({
  startDate, endDate, onCustomStartChange, onCustomEndChange, dateTemplate,
}) {
  const maxDayRange = 31

  return (
    <div>
      <div className="flex flex-row gap-2 align-items-center">
        <Calendar
          value={startDate.toDate()}
          onChange={(e) => onCustomStartChange(moment(e.value))}
          dateFormat="M d, yy"
          maxDate={endDate.toDate()}
          minDate={endDate.clone().subtract(maxDayRange - 1, 'days').toDate()}
          dateTemplate={dateTemplate}
          showIcon
        />
        <div>-</div>
        <Calendar
          value={endDate.toDate()}
          onChange={(e) => onCustomEndChange(moment(e.value))}
          minDate={startDate.toDate()}
          maxDate={startDate.clone().add(maxDayRange - 1, 'days').toDate()}
          dateFormat="M d, yy"
          dateTemplate={dateTemplate}
          showIcon
        />
      </div>
    </div>
  )
}

function DateInfoView({
  startDate,
  endDate,
  onPrevious,
  calendarDateTemplate,
  onNext,
  isNextDisabled,
  isCustom,
  onCustomStartChange,
  onCustomEndChange,
}) {
  if (isCustom) {
    return (
      <CustomDateRangeView
        startDate={startDate}
        endDate={endDate}
        onCustomStartChange={onCustomStartChange}
        onCustomEndChange={onCustomEndChange}
        dateTemplate={calendarDateTemplate}
      />
    )
  }

  return (
    <GranularityRangeView
      startDate={startDate}
      endDate={endDate}
      onPrevious={onPrevious}
      onNext={onNext}
      isNextDisabled={isNextDisabled}
    />
  )
}

export default DateInfoView
