import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const reportTypeDateRange = (reportType) => {
  switch (reportType) {
    case 'prn_mar':
    case 'medication_errors':
    case 'clinical_notes':
    case 'bowel_movements':
    case 'barcode_scans':
    case 'late_doses':
    case 'narcotic_counts':
      return {
        rangeStart: moment().subtract(2, 'years'),
        rangeEnd: moment(),
      }
    case 'mar':
    case 'tar':
      return {
        rangeStart: moment().subtract(2, 'years'),
        rangeEnd: moment().add(3, 'months'),
      }
    case 'physician_orders':
      return {
        rangeStart: moment(),
        rangeEnd: moment().add(3, 'months'),
      }
    default:
      return {
        rangeStart: moment().subtract(2, 'years'),
        rangeEnd: moment(),
      }
  }
}
