import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { confirmDialog } from 'primereact/confirmdialog'
import ActionsTable from '@components/conditions/ActionsTable'
import ConditionNote from '@components/conditions/ConditionNote'
import ConditionScheduleSelector from '@components/conditions/ConditionScheduleSelector'
import { useDeleteTaskCondition, useUpdateTaskCondition } from '@hooks/taskConditions'

function EditTaskConditionDialog({
  condition,
  statusMessage,
  schedules = [],
  existingConditions,
  onHide,
  isPrn,
  visible,
}) {
  const [actions, setActions] = useState(condition.actions || [])
  const [conditionNote, setConditionNote] = useState(condition.note)
  const [selectedSchedules, setSelectedSchedules] = useState(
    condition.schedules.map((s) => s.id),
  )

  const {
    mutateAsync: deleteCondition,
    isLoading: isDeletingCondition,
  } = useDeleteTaskCondition(statusMessage)

  const {
    mutateAsync: updateCondition,
    isLoading: isUpdatingCondition,
  } = useUpdateTaskCondition(statusMessage)

  const onUpdateCondition = async () => {
    await updateCondition(
      {
        id: condition.id,
        note: conditionNote,
        scheduleIds: selectedSchedules.map((scheduleId) => parseInt(scheduleId, 10)),
        actions,
      },
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const onDeleteCondition = async () => {
    await deleteCondition(
      {
        id: condition.id,
      },
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <h3 className="font-semibold text-lg flex flex-column gap-2">
        {condition.conditionName}
      </h3>
      <Button
        icon="pi pi-trash"
        className="p-button-outlined p-button-text"
        type="button"
        loading={isDeletingCondition}
        style={{ color: 'var(--red-500)' }}
        onClick={() => confirmDialog({
          message: 'Are you sure you want to delete this criteria / vitals?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: onDeleteCondition,
        })}
      />
    </div>
  )

  const otherConditionsForType = existingConditions
    .filter((c) => c.conditionType === condition.conditionType && c.id !== condition.id)

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
      style={{ width: '75vw' }}
    >
      <div className="flex flex-column gap-4 align-items-center px-2">
        {
          !isPrn && (
            <ConditionScheduleSelector
              conditionType={condition.conditionType}
              selectableSchedules={schedules}
              otherConditionsForType={otherConditionsForType}
              selectedSchedules={selectedSchedules}
              setSelectedSchedules={setSelectedSchedules}
            />
          )
        }
        <ConditionNote
          conditionNote={conditionNote}
          conditionType={condition.conditionType}
          setConditionNote={setConditionNote}
        />
        <ActionsTable
          actions={actions}
          conditionType={condition.conditionType}
          setActions={setActions}
        />
        <Button
          label="Update"
          className="flex w-full p-button-sm"
          onClick={onUpdateCondition}
          loading={isUpdatingCondition}
        />
      </div>
    </Dialog>
  )
}

export default EditTaskConditionDialog
