import React from 'react'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'
import ColoredCircle from './ColoredCircle'

function NarcoticCountDetails({ narcoticCount, timezone }) {
  const {
    dueAt, inventoryClaim1, inventoryClaim2, status,
  } = narcoticCount
  const typeLabel = {
    ad_hoc: 'Ad Hoc',
    med_pass: 'Med Pass',
    shift_change: 'Shift Change',
  }

  return (
    <div className="flex flex-column row-gap-2 m-1">
      {
        status && (
          <div className="flex flex-row gap-1 align-items-center text-left">
            <ColoredCircle type={status} />
            <span className="text-base font-medium line-height-2">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          </div>
        )
      }
      <span className="text-xs">
        {typeLabel[narcoticCount.countType]}
      </span>
      { dueAt && (
      <div className="flex flex-row gap-1">
        <span className="text-xs">Due:</span>
        <span className="text-xs font-medium">{ moment(dueAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
      </div>
      )}
      { inventoryClaim1 && (
        <div className="flex flex-row gap-1">
          <div className="flex flex-row gap-1">
            <span className="text-xs">Claim 1:</span>
            <span className="text-xs font-medium">{ moment(inventoryClaim1.createdAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{inventoryClaim1.claimedBy?.fullName || 'Unspecified'}</span>
            <span className="text-xs">
              Count:
            </span>
            <span className="text-xs font-medium">
              {inventoryClaim1.count}
            </span>
          </div>
        </div>
      )}
      { inventoryClaim2 && (
        <div className="flex flex-row gap-1">
          <div className="flex flex-row gap-1">
            <span className="text-xs">Claim 2:</span>
            <span className="text-xs font-medium">{ moment(inventoryClaim2.createdAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{inventoryClaim2.claimedBy?.fullName || 'Unspecified'}</span>
            <span className="text-xs">
              Count:
            </span>
            <span className="text-xs font-medium">
              {inventoryClaim2.count}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default NarcoticCountDetails
