import React from 'react'
import { Divider } from 'primereact/divider'
import { isEmpty } from 'lodash'
import ConditionCheckDetails from './StatusOverlayDetails/ConditionCheckDetails'
import AdHocMeasurementDetails from './StatusOverlayDetails/AdHocMeasurementDetails'

/**
 * Overlay that displays the status of conditionChecks.
 *
 * @param {Object} conditionChecks - The selected cell containing details.
 * @param {string} timezone - The timezone of the organization.
 * @returns {JSX.Element} - JSX element representing the overlay.
 */
function StatusOverlay({
  vitals,
  timezone,
}) {
  if (isEmpty(vitals)) return null

  return (
    <div className="w-22rem flex flex-column">
      {
        vitals.map((vital) => (
          vital.adhoc
            ? (
              <AdHocMeasurementDetails
                key={vital.id}
                measurement={vital}
                timezone={timezone}
              />
            )
            : (
              <ConditionCheckDetails
                key={vital.id}
                conditionCheck={vital}
                timezone={timezone}
              />
            )
        )).reduce((prev, curr) => [prev, <Divider />, curr])
      }
    </div>
  )
}

export default StatusOverlay
