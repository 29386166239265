import React from 'react'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'
import ColoredCircle from '../../ColoredCircle'
import { conditionCheckTakenAt, conditionCheckTakenBy } from '../VitalDashboardUtils'

function ConditionCheckDetails({ conditionCheck, timezone }) {
  const {
    status, expectedAt, actionedAt, skippedBy,
  } = conditionCheck
  const completedAt = conditionCheckTakenAt(conditionCheck)
  const formattedActionedAt = moment(actionedAt).tz(timezone).format(momentFormats.dateYearTime)
  const takenBy = conditionCheckTakenBy(conditionCheck)

  return (
    <div className="flex flex-column row-gap-2 m-1">
      { status && (
        <div className="flex flex-row gap-1 align-items-center text-left">
          <ColoredCircle type={status} />
          <span className="text-base font-medium line-height-2">
            { status.charAt(0).toUpperCase() + status.slice(1) }
          </span>
        </div>
      )}
      { expectedAt && (
      <div className="flex flex-row gap-1">
        <span className="text-xs">Expected:</span>
        <span className="text-xs font-medium">
          { moment(expectedAt).tz(timezone).format(momentFormats.dateYearTime) }
        </span>
      </div>
      )}
      { completedAt && (
      <div className="flex flex-row gap-1">
        <span className="text-xs">Completed:</span>
        <span className="text-xs font-medium">
          { moment(completedAt).tz(timezone).format(momentFormats.dateYearTime) }
        </span>
        <span className="text-xs">by</span>
        <span className="text-xs font-medium">
          { takenBy?.fullName || 'Unspecified' }
        </span>
      </div>
      )}
      { status === 'skipped' && skippedBy && (
        <div className="flex flex-row gap-1">
          <span className="text-xs">Skipped: </span>
          <span className="text-xs font-medium">{ formattedActionedAt }</span>
          <span className="text-xs">by</span>
          <span className="text-xs font-medium">{ skippedBy.fullName || 'Unspecified' }</span>
        </div>
      )}
    </div>
  )
}

export default ConditionCheckDetails
