import { useQueryClient, useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useDeactivatePharmacist(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['deactivatePharmacist'],
    mutationFn: ({ pharmacistId, note }) => (
      ApiClient.post(`/users/${pharmacistId}/deactivate`, { note })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Pharmacist was deactivated successfully',
        },
      ])
      queryClient.invalidateQueries('pharmacists')
      queryClient.invalidateQueries('deactivatedPharmacists')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDeactivatePharmacist
