import React, {
  memo, useRef, useState,
} from 'react'
import { get } from 'lodash'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useCurrentUser } from '@components/App'
import DateTable from '@components/display/DateTable/DateTable'
import { useMarDashboard } from '@hooks/dashboards'
import StatusIndicator from './StatusIndicator'
import AdherenceTableHeader from '../AdherenceTableHeader'
import MarSigDetails from '../MarSigDetails'
import MARApprovalsTable from '../MARApproval/MARApprovalTable'
import StatusOverlay from '../StatusOverlay'
import ApprovalOverlay from '../MARApproval/ApprovalOverlay'
import { marColumns } from '../utils'
import DoseDetails from '../DoseDetails'
import MarDialog from '../MarDialog'
import AdherenceLOADialog from '../../AdherenceLOADialog'
import AdherenceDoseHoldDialog from '../../AdherenceDoseHoldDialog'

const ScheduledConsumptions = memo(({
  dateRange,
  patient,
  marApprovals,
  isApprovalsLoading,
  statusMessageRef,
}) => {
  const {
    data: marDashboard,
    isLoading: isMarDashboardLoading,
  } = useMarDashboard(patient.id, dateRange, 'routine', statusMessageRef)

  const consumptionOverlay = useRef(null)
  const dateOverlay = useRef(null)

  const [dateHovered, setDateHovered] = useState()

  const [dialogMode, setDialogMode] = useState()
  const [selectedConsumption, setSelectedConsumption] = useState()
  const [selectedMarTimeRow, setSelectedMarTimeRow] = useState()
  const [selectedCellTime, setSelectedCellTime] = useState()

  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === 'site_admin'
  const dateMarApprovals = dateHovered ? marApprovals.filter((mar) => mar.days.includes(dateHovered.format('YYYY-MM-DD'))) : []
  const canChangeMar = ['site_admin', 'caregiver'].includes(currentUser.role)

  // Show/hide consumption overlay for consumption details
  const showConsumptionOverlay = (consumption, e) => {
    setSelectedConsumption(consumption)
    if (consumptionOverlay.current) {
      consumptionOverlay.current.hide()
      requestAnimationFrame(() => {
        consumptionOverlay.current.show(e)
      })
    } else {
      consumptionOverlay.current.show(e)
    }
  }

  const hideConsumptionOverlay = () => {
    if (consumptionOverlay.current) {
      consumptionOverlay.current.hide()
    }
  }

  // Show/hide date overlay for MAR approvals
  const showDateOverlay = (date, e) => {
    setDateHovered(date)
    if (dateOverlay.current) {
      dateOverlay.current.hide()
      requestAnimationFrame(() => {
        dateOverlay.current.show(e)
      })
    } else {
      dateOverlay.current.show(e)
    }
  }

  const hideDateOverlay = () => {
    if (dateOverlay.current) {
      dateOverlay.current.hide()
    }
  }

  const onEmptyCellClick = (marTimeRow, cellTime) => {
    setSelectedConsumption(null)
    setSelectedMarTimeRow(marTimeRow)
    setSelectedCellTime(cellTime)
    setDialogMode('add')
  }

  const onConsumptionEdit = (consumptionWithHistory) => {
    setSelectedConsumption(consumptionWithHistory)
    if (consumptionWithHistory.status === 'LOA') {
      setDialogMode('LOA')
    } else if (consumptionWithHistory.status === 'on_hold') {
      setDialogMode('hold')
    } else {
      setDialogMode('edit')
    }
    consumptionOverlay.current.hide()
  }

  const onConsumptionSelect = (consumption, marTimeRow, cellTime, e) => {
    setSelectedMarTimeRow(marTimeRow)
    setSelectedCellTime(cellTime)
    showConsumptionOverlay(consumption, e)
  }

  const onMarDialogHide = () => {
    setSelectedConsumption(null)
    setDialogMode(null)
    hideConsumptionOverlay()
  }

  const consumptionTemplate = (marTimeRow, column) => (
    <StatusIndicator
      consumptions={get(marTimeRow.consumptionsByDate, column.field) || []}
      marTimeRow={marTimeRow}
      dateStr={column.field}
      timeStr={marTimeRow.time}
      patientTimezone={patient.timezone}
      isAdmin={isAdmin}
      onEmptyCellClick={onEmptyCellClick}
      onConsumptionSelect={onConsumptionSelect}
    />
  )

  const headerTemplate = (date, formattedDate, approvals) => (
    <AdherenceTableHeader
      date={date}
      formattedDate={formattedDate}
      approvals={approvals}
      showDateOverlay={showDateOverlay}
      hideDateOverlay={hideDateOverlay}
    />
  )

  const doseTemplate = (marTimeRow) => (
    <DoseDetails marTimeRow={marTimeRow} />
  )

  const detailsTemplate = (marTimeRow) => (
    <MarSigDetails doseSig={marTimeRow.doseSig} />
  )

  const columns = marColumns(
    {
      dateRange,
      marApprovals,
      doseTemplate,
      doseSigTemplate: detailsTemplate,
      headerTemplate,
      consumptionTemplate,
    },
  )

  const timezone = selectedConsumption?.timezone || patient.timezone

  return (
    <div className="datatable-container flex flex-column">
      <div className="flex flex-column gap-3 text-base text-900" style={{ overflowX: 'auto' }}>
        <DateTable
          data={marDashboard?.marTimeRows || []}
          columns={columns}
          isLoading={isMarDashboardLoading}
          tableClassName="adherence-table"
          emptyMessage="No medications available"
          className="schedule-mar-view"
          sortField="medicine"
          // disabling row grouping temporarily to find solution for primereact scroll bug
          // rowGroupMode="rowspan"
          // groupRowsBy={['doseSig.dose.id', 'doseSig.id']}
          sortMode="single"
          scrollHeight="70vh"
        />
        <MARApprovalsTable
          marApprovals={marApprovals}
          isLoading={isApprovalsLoading}
        />
      </div>
      <OverlayPanel ref={consumptionOverlay} className="adherence-status-overlay">
        <StatusOverlay
          consumption={selectedConsumption}
          canEdit={isAdmin}
          onConsumptionEdit={onConsumptionEdit}
        />
      </OverlayPanel>
      <OverlayPanel ref={dateOverlay} className="date-overlay">
        <ApprovalOverlay marApprovals={dateMarApprovals} />
      </OverlayPanel>
      {
        canChangeMar && (
          <>
            <MarDialog
              visible={!!dialogMode && (!['LOA', 'hold'].includes(dialogMode))}
              onHide={onMarDialogHide}
              consumption={selectedConsumption}
              cellTime={selectedCellTime}
              marTimeRow={selectedMarTimeRow}
              timezone={timezone}
              mode={dialogMode}
              patientId={patient.id}
            />
            <AdherenceLOADialog
              patient={patient}
              visible={dialogMode === 'LOA'}
              onHide={onMarDialogHide}
              time={selectedConsumption?.expectedAt}
              statusMessageRef={statusMessageRef}
              timezone={timezone}
            />
            <AdherenceDoseHoldDialog
              doseId={selectedConsumption?.doseId}
              visible={dialogMode === 'hold'}
              onHide={onMarDialogHide}
              time={selectedConsumption?.expectedAt}
              statusMessageRef={statusMessageRef}
              timezone={timezone}
            />
          </>
        )
      }
    </div>
  )
})

ScheduledConsumptions.displayName = 'ScheduledConsumptions'
export default ScheduledConsumptions
