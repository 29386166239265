import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { buildAvailableTimes } from '@components/clientDoses/doseUtils'

function DiscontinueDoseDialog({
  dose, discontinueDose, visible, onHide,
}) {
  const [endAdministrationTime, setEndAdministrationTime] = useState(null)

  let availableTimes = []

  if (dose.schedules) {
    availableTimes = buildAvailableTimes(dose.schedules)
  }

  const handleCloseDialog = () => {
    setEndAdministrationTime(null)
    onHide()
  }

  const handleDiscontinueDoseNow = () => {
    discontinueDose({ id: dose.id, endAdministrationTime })
    onHide()
  }

  return (
    <Dialog header="End Medication Now" visible={visible} onHide={handleCloseDialog}>
      <div className="flex flex-column gap-4">
        <label>Optionally select an end administration time for the dose:</label>
        <Dropdown
          showClear
          placeholder="Select a time"
          value={endAdministrationTime}
          options={availableTimes}
          onChange={(e) => setEndAdministrationTime(e.value)}
        />
        <div className="flex flex-row justify-content-center">
          <Button label="End Now" icon="pi pi-check" onClick={handleDiscontinueDoseNow} />
        </div>
      </div>
    </Dialog>
  )
}

export default DiscontinueDoseDialog
