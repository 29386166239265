import React, { useRef } from 'react'
import { Divider } from 'primereact/divider'
import { Toast } from 'primereact/toast'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { Attribute } from '@components/display/AttributeCard'
import PasswordInput from '@components/user/PasswordInput'
import UsernameInput from '@components/user/UsernameInput'
import { useClearPassword, useSetTemporaryPassword, useSetUsername } from '@hooks/auth'

// Maintain consistency with app/services/capabilities/user.rb
const showCredentials = (organization, currentUser, user) => {
  if (!currentUser || !user) {
    return false
  }

  if (!organization && user.role !== 'pharmacist') {
    return false
  }

  if (organization && !organization.usernamePasswordAuthEnabled) {
    return false
  }

  const isSuperadmin = currentUser.role === 'superadmin'
  const isAdmin = currentUser.role === 'site_admin' || isSuperadmin

  switch (user.role) {
    case 'caregiver':
      return isAdmin
    case 'guardian':
      return isAdmin
    case 'patient':
      return isAdmin && user.patientSettings?.selfAdmin
    case 'pharmacist':
      return isSuperadmin
    case 'site_admin':
      return isSuperadmin || currentUser.id === user.id
    default:
      return false
  }
}

function UserCredentials({ user }) {
  const statusMessageRef = useRef()
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()

  const {
    mutate: setUsername,
  } = useSetUsername(statusMessageRef, user?.role)

  const {
    mutate: clearPassword,
  } = useClearPassword(statusMessageRef, user?.role)

  const {
    mutate: setTemporaryPassword,
  } = useSetTemporaryPassword(statusMessageRef, user?.role)

  if (!showCredentials(organization, currentUser, user)) {
    return null
  }

  const usernameTemplate = () => (
    <UsernameInput
      user={user}
      onSubmit={(newUsername) => {
        if (user.username !== newUsername) {
          setUsername({ userId: user.id, username: newUsername })
        }
      }}
    />
  )

  const passwordTemplate = () => (
    <PasswordInput
      user={user}
      onClear={() => clearPassword({ userId: user.id })}
      onSubmit={(newPassword) => {
        if (user.temporaryPassword !== newPassword) {
          setTemporaryPassword({ userId: user.id, temporaryPassword: newPassword })
        }
      }}
    />
  )

  return (
    <>
      <Toast ref={statusMessageRef} />
      <div className="flex gap-2 align-items-center">
        <span className="text-lg mt-3">
          <i className="pi pi-lock mr-2" />
          Credentials
        </span>
      </div>
      <Divider className="p-0 my-0" />
      <Attribute
        label="Username:"
        value={usernameTemplate()}
      />
      <Attribute
        label="Password:"
        value={passwordTemplate()}
      />
    </>
  )
}

export default UserCredentials
