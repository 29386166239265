import React, { useRef, useState } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { Calendar } from 'primereact/calendar'
import { useCurrentOrganization } from '@components/App'

function MonthlyRangeExportDialog({
  patientId,
  isVisible,
  setIsVisible,
  exportMutation,
  dialogTitle,
}) {
  const organization = useCurrentOrganization()
  const messages = useRef()
  const {
    mutateAsync: exportData,
    isLoading: isExporting,
  } = exportMutation(messages, patientId)

  const [startDate, setStartDate] = useState(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState(moment().startOf('month').toDate())

  const handleExport = async () => {
    exportData({
      startYear: startDate.getFullYear(),
      startMonth: startDate.getMonth() + 1, // getMonth() counts months from 0 to 11
      endYear: endDate.getFullYear(),
      endMonth: endDate.getMonth() + 1, // getMonth() counts months from 0 to 11
    }, {
      onSuccess: () => { setIsVisible(false) },
    })
  }

  return (
    <Dialog
      header={`Export ${organization.patientLabelSingular} ${dialogTitle}`}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={messages} />
      <div className="flex flex-column text-base font-normal mt-2">
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal w-2">From:</span>
          <Calendar
            id="monthly-range-export-start-date"
            className="mt-1 mb-3 border-round-md"
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            view="month"
            dateFormat="MM yy"
            maxDate={endDate}
            showIcon
          />
        </div>
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal w-2">To:</span>
          <Calendar
            id="monthly-range-export-end-date"
            className="mt-1 mb-3 border-round-md"
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            view="month"
            dateFormat="MM yy"
            minDate={startDate}
            showIcon
          />
        </div>
        <Button
          className="p-button-sm p-button-outlined"
          label="Export"
          onClick={handleExport}
          loading={isExporting}
        />
      </div>
    </Dialog>
  )
}

export default MonthlyRangeExportDialog
