import React, { useMemo, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import { generateMonthYearsDropdownOptions, isMonthYearOptionAfterOrSameAsNow } from '@services/utils/moment'
import { reportTypeDateRange } from '@services/utils/reports'
import { find, first } from 'lodash'
import { InputSwitch } from 'primereact/inputswitch'

function MonthlyExportDialog({
  patientId,
  isVisible,
  setIsVisible,
  exportMutation,
  dialogTitle,
  reportType,
  showBlankReportSwitch,
}) {
  const organization = useCurrentOrganization()
  const messages = useRef()
  const {
    mutateAsync: exportData,
    isLoading: isExporting,
  } = exportMutation(messages, patientId)

  const [blankReport, setBlankReport] = useState(false)

  const dateRange = useMemo(
    () => reportTypeDateRange(reportType),
    [reportType],
  )

  const monthYearsMenuOptions = useMemo(
    () => generateMonthYearsDropdownOptions(dateRange.rangeStart, dateRange.rangeEnd),
    [dateRange],
  )
  const [selectedMonthYear, setSelectedMonthYear] = useState(find(monthYearsMenuOptions, 'current') ?? first(monthYearsMenuOptions))

  const handleExport = async () => {
    exportData({
      year: selectedMonthYear.year,
      month: selectedMonthYear.month,
      isBlank: blankReport && isMonthYearOptionAfterOrSameAsNow(selectedMonthYear),
    }, {
      onSuccess: () => {
        setIsVisible(false)
      },
    })
  }
  return (
    <Dialog
      header={`Export ${organization.patientLabelSingular} ${dialogTitle}`}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={messages} />
      <div className="flex flex-column text-base font-normal mt-2">
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal">Export from:</span>
          <Dropdown
            value={selectedMonthYear}
            options={monthYearsMenuOptions}
            onChange={(e) => {
              setSelectedMonthYear(e.value)
            }}
            placeholder="Select a Month"
            className="flex-grow-1"
          />
        </div>
        {(showBlankReportSwitch && isMonthYearOptionAfterOrSameAsNow(selectedMonthYear)) && (
          <div className="flex flex-row gap-2 align-items-center mb-2">
            <InputSwitch
              checked={blankReport}
              onChange={(e) => setBlankReport(e.value)}
            />
            <span className="font-normal">Blank Report</span>
          </div>
        )}
        <Button
          className="p-button-sm p-button-outlined"
          label="Export"
          onClick={handleExport}
          loading={isExporting}
        />
      </div>
    </Dialog>
  )
}

export default MonthlyExportDialog
