import React from 'react'
import moment from 'moment-timezone'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  ACTIVITY_TYPES, BRISTOL_STOOL_SCALE, SIZES, WITNESS_TYPE,
} from '../patients/config'
import { dateTimeTemplateFromMoment } from '../../services/utils'

function BowelMovementsTable({ bowelMovements, loading, className }) {
  const bristolTypeTemplate = (bowelMovement) => (
    bowelMovement.bristolType ? (
      <div className="flex flex-column gap-2">
        <span>
          Type
          {' '}
          {bowelMovement.bristolType}
        </span>
        <span>{BRISTOL_STOOL_SCALE[bowelMovement.bristolType].description}</span>
      </div>
    ) : (
      <span>Not Entered</span>
    )
  )

  return (
    <DataTable
      value={bowelMovements}
      loading={loading}
      className={className}
      emptyMessage="No bowel movements reported"
      dataKey="id"
      sortField="reportedAt"
      sortOrder={1}
    >
      <Column
        field="reportedAt"
        header="Reported At"
        body={(bowelMovement) => dateTimeTemplateFromMoment(moment(bowelMovement.reportedAt))}
        sortable
      />
      <Column
        field="reportedBy"
        header="Reported By"
        body={(bowelMovement) => bowelMovement.reportedBy.fullName}
        sortable
      />
      <Column
        field="activityType"
        header="Activity Type"
        sortable
        body={(bowelMovement) => (bowelMovement.activityType ? ACTIVITY_TYPES[bowelMovement.activityType].name : 'Not Entered')}
      />
      <Column
        field="bristolType"
        header="Bristol Type"
        sortable
        body={bristolTypeTemplate}
      />
      <Column
        field="size"
        header="Size"
        sortable
        body={(bowelMovement) => (bowelMovement.size ? SIZES[bowelMovement.size].name : 'Not Entered')}
      />
      <Column
        field="witness"
        header="Witness"
        sortable
        body={(bowelMovement) => (bowelMovement.witness ? WITNESS_TYPE[bowelMovement.witness].name : 'Not Entered')}
      />
    </DataTable>
  )
}

export default BowelMovementsTable
