import React, { useState, useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import { map } from 'lodash'
import { allowedHrstPurposes } from '../../../features/Patients/config'

function DosePurposeField({
  purpose,
  hrstOtherPurpose,
  newPurpose,
  setNewPurpose,
  setNewOtherPurpose,
  isEditing,
  newOtherPurpose,
  doseSigs,
}) {
  const [items, setItems] = useState([])
  const [otherPurposes, setOtherPurposes] = useState([])

  // check for other purposes and group them together removing duplicates
  // and arranging in order from most frequent to least frequent
  // finally, set the state of other purposes
  useEffect(() => {
    const purposeCount = {}
    doseSigs.forEach((doseSig) => {
      if (doseSig.hrstOtherPurpose) {
        purposeCount[doseSig.hrstOtherPurpose] = (purposeCount[doseSig.hrstOtherPurpose] ?? 0) + 1
      }
    })
    setOtherPurposes(Object.keys(purposeCount).sort((a, b) => purposeCount[b] - purposeCount[a]))
  }, [doseSigs])

  const search = ({ query }) => {
    const filtered = otherPurposes.filter((i) => i.toLowerCase().includes(query.toLowerCase()))
    setItems(filtered)
  }

  if (isEditing) {
    return (
      <div className="flex flex-column gap-2">
        <Dropdown
          className="w-12"
          value={newPurpose}
          options={map(allowedHrstPurposes, (value, key) => ({ label: value, value: key }))}
          dataKey="value"
          dataValue="label"
          onChange={(e) => setNewPurpose(e.value)}
          placeholder="Select a purpose"
        />
        {newPurpose === 'OTHER' && (
          <AutoComplete
            placeholder="Enter other purpose"
            value={newOtherPurpose}
            suggestions={items}
            completeMethod={search}
            onChange={(e) => setNewOtherPurpose(e.target.value)}
          />
        )}
      </div>
    )
  }

  if (!purpose) {
    return (
      <div className="flex flex-row gap-1">
        <i className="pi pi-exclamation-triangle text-orange-500" />
        <span className="text-xs">Click edit to set purpose</span>
      </div>
    )
  }

  return (
    <div className="flex flex-column gap-2" style={{ minWidth: '160px', maxWidth: '160px' }}>
      <span>{allowedHrstPurposes[purpose]}</span>
      {purpose === 'OTHER' && <span>{hrstOtherPurpose}</span>}
    </div>
  )
}

export default DosePurposeField
