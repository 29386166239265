import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
import { momentToDatePreservingTime, momentTzWithoutChangingTime } from '@services/utils/moment'
import DeleteButton from '../DeleteButton'

function LeaveOfAbsenceForm({
  onCancel,
  onConfirm,
  onDelete,
  isLoading,
  mode = 'add',
  timezone,
  defaultStartAt,
  defaultEndAt,
  defaultNote = '',
  showDeleteButton = false,
  showCancel = true,
}) {
  const [startMoment, setStartMoment] = useState(moment().tz(timezone))
  const [endMoment, setEndMoment] = useState(null)
  const [note, setNote] = useState('')

  useEffect(() => {
    if (defaultStartAt) {
      setStartMoment(moment(defaultStartAt).tz(timezone))
    }
    if (defaultEndAt) {
      setEndMoment(moment(defaultEndAt).tz(timezone))
    }
    setNote(defaultNote)
  }, [defaultStartAt, defaultEndAt, defaultNote, timezone])

  // TODO: we should not allow creating LOA that overlaps with already existing ones
  return (
    <div className="flex flex-column gap-2">
      <div className="p-field flex flex-column w-12">
        <label htmlFor="pause-start-date">Start Date</label>
        <Calendar
          id="startDateTime"
          name="pause-start-date"
          className="mt-2 border-round-md"
          value={momentToDatePreservingTime(startMoment, timezone)}
          stepMinute={15}
          stepHour={1}
          onChange={(e) => {
            setStartMoment(momentTzWithoutChangingTime(moment(e.value), timezone))
          }}
          showTime
          hourFormat="12"
          showIcon
          icon={<i className="pi pi-clock" />}
          hideOnDateTimeSelect
        />
      </div>
      <div className="p-field flex flex-column w-12">
        <label htmlFor="pause-end-date">End Date</label>
        <Calendar
          id="endDateTime"
          name="pause-end-date"
          className="mt-2 border-round-md"
          value={momentToDatePreservingTime(endMoment, timezone)}
          stepMinute={15}
          onChange={(e) => {
            setEndMoment(momentTzWithoutChangingTime(moment(e.value), timezone))
          }}
          showButtonBar
          showTime
          hourFormat="12"
          showIcon
          icon={<i className="pi pi-clock" />}
          hideOnDateTimeSelect
        />
      </div>
      <div className="p-field flex flex-column w-12">
        <label htmlFor="pause-patient-note">
          Note for this leave of absence
        </label>
        <InputTextarea
          id="pause-patient-note"
          className="mt-2 border-round-md"
          rows={3}
          cols={30}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <div className="flex flex-column gap-2">
        <div className="flex flex-row gap-2 justify-content-center">
          {showCancel && (
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-outlined p-button-sm"
            onClick={onCancel}
            disabled={isLoading}
          />
          )}
          <Button
            label={mode === 'add' ? 'Create' : 'Update'}
            icon="pi pi-check"
            className="p-button-sm"
            onClick={() => onConfirm({ startAt: startMoment, endAt: endMoment, note })}
            loading={isLoading}
          />
        </div>
        {showDeleteButton && (
          <DeleteButton
            onClick={onDelete}
            isLoading={isLoading}
            showConfirm
            confirmMessage="Are you sure you want to delete this leave of absence?"
          />
        )}
      </div>
    </div>
  )
}

export default LeaveOfAbsenceForm
