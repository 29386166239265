import React, { useState, useEffect, useCallback } from 'react'
import { AutoComplete } from 'primereact/autocomplete'

export function Autocomplete({
  options,
  displayProperty,
  onOptionSelect,
  placeholder = 'Type to search...',
  debounceDelay = 250,
  dropdown = true,
}) {
  const [inputText, setInputText] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])

  // Filter the list based on the current input text
  const filterOptions = useCallback((text) => {
    if (!text.trim()) {
      setFilteredOptions([])
      return
    }

    const filtered = options.filter((option) => {
      const value = option[displayProperty]
      return typeof value === 'string' && value.toLowerCase().includes(text.toLowerCase())
    })

    setFilteredOptions(filtered)
  }, [options, displayProperty])

  // Debounce filtering: run the filtering function only after the user has stopped typing
  useEffect(() => {
    const handler = setTimeout(() => {
      filterOptions(inputText)
    }, debounceDelay)

    return () => clearTimeout(handler)
  }, [inputText, filterOptions, debounceDelay])

  // When the user types into the input, update the input text.
  const handleChange = (e) => {
    setInputText(e.value)
  }

  // The completeMethod is called by the AutoComplete when a search is triggered.
  // We update the input text so that it is used by our effect.
  const handleComplete = (e) => {
    setInputText(e.query)
  }

  // When an option is selected, update the text to show the selected value,
  // clear the suggestions, and call the provided callback.
  const handleSelect = (e) => {
    const selectedOption = e.value
    setInputText(selectedOption[displayProperty])
    setFilteredOptions([])
    onOptionSelect(selectedOption)
  }

  return (
    <AutoComplete
      value={inputText}
      suggestions={filteredOptions}
      completeMethod={handleComplete}
      field={displayProperty}
      onChange={handleChange}
      onSelect={handleSelect}
      placeholder={placeholder}
      dropdown={dropdown}
    />
  )
}

export default Autocomplete
