import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'
import { downloadFileFromBase64Response } from '@services/utils'

export default function useCreateReportMar(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['reportMar'],
    mutationFn: async ({ year, month, isBlank }) => {
      const response = await ApiClient.get('/reports/mars', {
        headers: { Accept: 'application/pdf' },
        params: {
          patientId,
          month,
          year,
          isBlank,
        },
      })
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
