import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import DeleteSmartFlipDialog from './DeleteSmartFlipDialog'

export function LinkedSmartFlip({ prescription, smartFlip }) {
  const messagesRef = useRef()
  const [showDialog, setShowDialog] = useState(false)

  const { id, patientId, prescriptionNumber } = prescription

  const path = `/admin/patients/${patientId}/prescriptions/manage/${id}`

  return (
    <div className="flex flex-row align-items-center gap-2">
      <Toast ref={messagesRef} />
      <DeleteSmartFlipDialog
        smartFlip={smartFlip}
        isVisible={showDialog}
        messagesRef={messagesRef}
        onHide={() => setShowDialog(false)}
      />
      <a className="text-blue-500 hover:text-blue-700" href={path}>
        {prescriptionNumber}
      </a>
      <Button
        label="Unlink"
        className="p-button-text p-button-sm"
        icon="fa-solid fa-unlink"
        onClick={() => setShowDialog(true)}
        style={{ padding: 0 }}
      />
    </div>
  )
}

export default LinkedSmartFlip
