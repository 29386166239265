import React from 'react'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'

function AdHocMeasurementDetails({ measurement, timezone }) {
  const { createdAt, takenBy } = measurement
  const formattedCreatedAt = moment(createdAt).tz(timezone).format(momentFormats.dateYearTime)

  return (
    <div className="flex flex-row gap-1 m-1">
      <span className="text-xs">Completed:</span>
      <span className="text-xs font-medium">{ formattedCreatedAt }</span>
      <span className="text-xs">by</span>
      <span className="text-xs font-medium">{ takenBy?.fullName || 'Unspecified' }</span>
    </div>
  )
}

export default AdHocMeasurementDetails
