import React, { useState, useEffect } from 'react'
import { get, map } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import SiteFilterDropdown from '@components/sites/SiteFilterDropdown'
import { exactSearch } from '@services/utils/search'
import { siteColumnBody } from '@services/utils'
import { usePharmacistsQuery } from './pharmacistsHooks'

function ActivePharmacists({
  statusMessageRef,
  maxDataViewHeight,
  setSearchLoading,
  searchTerm,
  rootPath,
  footer,
}) {
  const navigate = useNavigate()

  const [siteFilter, setSiteFilter] = useState('')
  const [filteredPharmacists, setFilteredPharmacists] = useState([])

  const { data: pharmacists, isLoading } = usePharmacistsQuery(statusMessageRef)

  useEffect(() => {
    const siteFilteredPharmacists = siteFilter ? pharmacists.filter((pharmacist) => get(pharmacist, 'sites').map((site) => site.name).includes(siteFilter)) : pharmacists
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredPharmacists(exactSearch(siteFilteredPharmacists, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPharmacists(siteFilteredPharmacists)
    }
    setSearchLoading(false)
  }, [pharmacists, searchTerm, siteFilter])

  const sitesOptions = Array.from(new Set(
    map(pharmacists, (pharmacist) => (get(pharmacist, 'sites') || []).map((site) => site.name)).flat(),
  ))

  const handleSiteChange = (e) => {
    setSiteFilter(e.value)
    document.querySelector('.p-column-filter-menu-button-open').click()
  }

  return (
    <div className="flex flex-column gap-3 text-base text-900">
      <DataTable
        dataKey="id"
        loading={isLoading}
        value={filteredPharmacists}
        selectionMode="single"
        onSelectionChange={(e) => navigate(`${rootPath}/${e.value.id}`)}
        sortField="lastName"
        sortOrder={1}
        className="mt-1"
        rowClassName="fadein pharmacist-row"
        scrollable
        scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
      >
        <Column header="First Name" field="firstName" />
        <Column header="Last Name" field="lastName" sortable />
        <Column
          header="Sites"
          field="sites"
          body={(rowData) => {
            const { sites } = rowData
            return siteColumnBody(sites)
          }}
          filter
          filterElement={(
            <SiteFilterDropdown
              sites={sitesOptions}
              value={siteFilter}
              handleSiteChange={handleSiteChange}
            />
          )}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showApplyButton={false}
          showClearButton={false}
          filterMenuClassName="pharmacists-site-filter"
        />
      </DataTable>
      {footer(filteredPharmacists.length, isLoading)}
    </div>
  )
}

export default ActivePharmacists
