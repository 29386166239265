import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Messages } from 'primereact/messages'
import SuperAdminCard from '@components/display/SuperAdminCard'
import WorkosMessagesTable from '@components/sso/WorkosMessagesTable'
import UserProfileCard from '@components/user/UserProfileCard'
import { useCurrentOrganization } from '@components/App'
import SiteSelection from '../sites/SiteSelection'
import { ExportConsumptionsButton } from '../../features/ExportConsumptionsButton/ExportConsumptionsButton'
import useOrgSitesQuery from '../sites/sitesHooks'
import { useUpdateAdminMutation, useUpdateAdminSettingsMutation } from '../../features/Admins/adminsHooks'
import NotificationTestButton from '../display/UserDevices/NotificationTestButton'

function AdminSites({
  adminId, admin, organization, statusMessage, isLoading, maxDataViewHeight,
}) {
  const {
    data: availableSites,
    isLoading: isLoadingSites,
  } = useOrgSitesQuery(organization.id, statusMessage)

  const {
    mutateAsync: updateAdmin,
    isLoading: isUpdatingAdmin,
  } = useUpdateAdminMutation(adminId, statusMessage)

  const {
    mutateAsync: updateAdminSettings,
    isLoading: isUpdatingAdminSettings,
  } = useUpdateAdminSettingsMutation(adminId, statusMessage)

  const handleCaregiverSitesChange = (sites) => {
    updateAdmin({
      siteAdminSiteAccessIds: sites.map((site) => site.id),
    })
  }

  const handleAllSitesAccessChange = (hasAllSitesAccess) => {
    updateAdminSettings({
      siteAccess: hasAllSitesAccess ? 'all' : 'assigned',
    })
  }

  if (isLoading) {
    return (
      <div className="col-12">
        <Skeleton className="inline-flex" width="5rem" height="1rem" />
      </div>
    )
  }

  return (
    <SiteSelection
      availableSites={availableSites}
      userSites={admin.sites}
      onUserSitesChange={(sites) => handleCaregiverSitesChange(sites)}
      isLoading={isLoadingSites || isUpdatingAdmin}
      showAllSitesAccess={!organization.deviceBasedAccess && !admin.isDirectoryManaged}
      maxDataViewHeight={maxDataViewHeight}
      allSitesAccessLoading={isUpdatingAdminSettings}
      isDirectoryManaged={admin.isDirectoryManaged}
      onAllSitesAccessChange={
        (hasAllSitesAccess) => handleAllSitesAccessChange(hasAllSitesAccess)
      }
      hasAllSitesAccess={admin.adminSettings.siteAccess === 'all'}
    />
  )
}

function AdminDashboard({
  adminId, admin, adminLoading, handleSetHeader, isCurrentUserAdmin, maxDataViewHeight,
}) {
  const statusMessage = useRef()
  const navigate = useNavigate()
  const organization = useCurrentOrganization()
  const canViewSites = organization.hasSites
    && (!organization.deviceBasedAccess || admin?.restrictSiteAccess)
  const canViewWorkosMessages = organization.ssoEnabled && admin?.isDirectoryManaged

  const primaryAction = isCurrentUserAdmin && (
    <div className="flex flex-row gap-2 pb-2">
      <NotificationTestButton userId={adminId} />
      {!organization.ccmEnabled
        && (
        <ExportConsumptionsButton
          userId={adminId}
          // eslint-disable-next-line
          role="admin"
        />
        )}
    </div>
  )

  useEffect(() => handleSetHeader({ primaryAction }), [admin])

  const onProfileEdit = () => {
    navigate(`/admin/admins/${admin.id}/update`)
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Messages ref={statusMessage} />
      <div className="col-6" data-testid="admin-profile-card">
        <UserProfileCard
          isLoading={adminLoading}
          onEdit={onProfileEdit}
          user={admin}
        />
      </div>
      <div className="col-12" data-testid="admin-sites-card">
        {
          canViewSites && (
            <AdminSites
              adminId={adminId}
              admin={admin}
              organization={organization}
              statusMessage={statusMessage}
              maxDataViewHeight={maxDataViewHeight}
              isLoading={adminLoading}
            />
          )
        }
      </div>
      {
        canViewWorkosMessages && (
          <div className="col-12" data-testid="admin-workos-messages-card">
            <SuperAdminCard title="WorkOS Messages">
              <WorkosMessagesTable
                id={adminId}
                model="User"
              />
            </SuperAdminCard>
          </div>
        )
      }
    </div>
  )
}

export default AdminDashboard
