import React, { useState, useEffect, useRef } from 'react'
import {
  compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { Menu } from 'primereact/menu'
import { Toast } from 'primereact/toast'
import { DetailsForm } from '@components/sites/SiteForm'
import { useDeleteSite, useUpdateSite } from '@hooks/sites'
import FormWrapper from '../../components/display/Form/FormWrapper'

function buildMenuItems({ handleDelete }) {
  const items = []

  items.push({
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => confirmDialog({
      message: 'Are you sure you want to delete this site?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => handleDelete(),
    }),
  })

  return items
}

function UpdateSite({ site, handleSetHeader, canManageSsoForOrg }) {
  const statusMessage = useRef(null)
  const siteActionMenu = useRef(null)
  const { siteId } = useParams()
  const navigate = useNavigate()

  const [details, setDetails] = useState({
    name: '',
    address: '',
    isScimManaged: false,
    scimGroup: '',
    timezone: '',
  })

  const {
    mutateAsync: updateSite,
    isLoading: updateIsLoading,
  } = useUpdateSite(statusMessage)

  const {
    mutateAsync: deleteSite,
  } = useDeleteSite(statusMessage)

  const handleUpdateSite = async () => {
    await updateSite({ ...details, id: siteId }, {
      onSuccess: (data) => {
        navigate(`/admin/sites/${data.id}`)
      },
    })
  }

  const handleDelete = async () => {
    await deleteSite(siteId, {
      onSuccess: () => {
        navigate('/admin/sites')
      },
    })
  }

  const breadcrumbs = compact([
    {
      label: 'Site',
      template: <Link to={`/admin/sites/${siteId}`}>Site</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ])

  const primaryAction = (
    <>
      <Menu
        model={buildMenuItems({
          handleDelete,
        })}
        popup
        ref={siteActionMenu}
        id="siteActionMenu"
      />
      <Button
        label="Actions"
        className="p-button-sm"
        icon="pi pi-chevron-down"
        onClick={(event) => siteActionMenu.current.toggle(event)}
      />
    </>
  )

  useEffect(() => {
    if (site) {
      setDetails({
        name: site.name,
        address: site.address,
        isScimManaged: site.isScimManaged,
        scimGroup: site.scimGroup,
        timezone: site.timezone,
      })
    }

    handleSetHeader({ breadcrumbs, primaryAction })
  }, [site])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-6">
        <FormWrapper title="Profile" fullHeight>
          <DetailsForm
            details={details}
            setDetails={(value) => setDetails((p) => ({ ...p, ...value }))}
            showScimDetails={canManageSsoForOrg}
          />
          <Button
            label="Save"
            className="p-button-sm mt-4"
            disabled={isEqual(details, pick(site, keys(details)))}
            loading={updateIsLoading}
            onClick={handleUpdateSite}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default UpdateSite
