import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { useCreateReportBowelMovements } from '@hooks/reports'
import BowelMovementsTable from '@components/bowelMovements/BowelMovementsTable'
import { usePatientBowelMovements } from './bowelMovementsHooks'
import MonthlyExportDialog from './MonthlyExportDialog'

function BowelMovements() {
  const [isExportDialogVisible, setIsExportDialogVisible] = useState(false)
  const { patientId } = useParams()
  const statusMessage = useRef(null)

  const {
    data: bowelMovements,
    isLoading,
  } = usePatientBowelMovements(patientId, statusMessage)

  return (
    <div className="col-12">
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={isExportDialogVisible}
        setIsVisible={setIsExportDialogVisible}
        exportMutation={useCreateReportBowelMovements}
        dialogTitle="Bowel Movements"
      />
      <Toast ref={statusMessage} />
      <div className="flex flex-column align-items-end gap-2">
        <Button
          label="Export"
          className="p-button-sm p-button-outlined"
          onClick={() => setIsExportDialogVisible(true)}
        />
        <BowelMovementsTable bowelMovements={bowelMovements} loading={isLoading} className="w-full" />
      </div>
    </div>
  )
}

export default BowelMovements
