import React, { createContext, useContext } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { camelizeKeys } from 'humps'
import * as Sentry from '@sentry/react'
import { Theme } from './Theme'
import UserSession from '../features/CurrentUser/UserSession'

const queryClient = new QueryClient()

const CurrentUserContext = createContext()
const CurrentOrganizationContext = createContext()
const CurrentCapabilitiesContext = createContext()
const FeatureFlagsContext = createContext()

function useCurrentUser() {
  const currentUserContext = useContext(CurrentUserContext)
  if (currentUserContext === undefined) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }
  return currentUserContext
}

function useCurrentOrganization() {
  const currentOrganizationContext = useContext(CurrentOrganizationContext)
  if (currentOrganizationContext === undefined) {
    throw new Error(
      'useCurrentOrganization must be used within a CurrentOrganizationProvider',
    )
  }
  return currentOrganizationContext
}

function useCurrentCapabilities() {
  const currentFeatureAccessContext = useContext(CurrentCapabilitiesContext)
  if (currentFeatureAccessContext === undefined) {
    throw new Error(
      'useCurrentCapabilities must be used within a CurrentCapabilitiesProvider',
    )
  }
  return currentFeatureAccessContext
}

function useFeatureFlags() {
  const featureFlagsContext = useContext(FeatureFlagsContext)
  if (featureFlagsContext === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider',
    )
  }
  return featureFlagsContext
}

function CurrentUserProvider({ currentUser, children }) {
  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  )
}

function CurrentOrganizationProvider({ currentOrganization, children }) {
  return (
    <CurrentOrganizationContext.Provider value={currentOrganization}>
      {children}
    </CurrentOrganizationContext.Provider>
  )
}

function CurrentCapabilitiesProvider({ capabilities, children }) {
  return (
    <CurrentCapabilitiesContext.Provider value={capabilities}>
      {children}
    </CurrentCapabilitiesContext.Provider>
  )
}

function FeatureFlagsProvider({ featureFlags, children }) {
  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

function App({ children, disableActiveAdminStyles }) {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Theme disableActiveAdminStyles={disableActiveAdminStyles}>
          {/*
            ConfirmDialog is a global component that should only be defined once and controlled
            with PrimeReact's confirmDialog function. The confirmDialog function will control
            all n declarations of this component which will show the dialog n times. Therefore,
            we declare the component once near the root of the component tree. We should favor
            a custom dialog component for non-trivial use cases.
          */}
          <ConfirmDialog />
          {children}
        </Theme>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

/**
 * This needs to be checked at runtime now that the containerized application is using the same
 * webpack bundle in staging and production.
 *
 * @returns {Object} An object containing environment specific values
 */
function getEnvironment() {
  switch (window.location.host) {
    case 'app.impruvonhealth.com':
      return { sentryEnvironment: 'production', mobileUrl: 'https://m.impruvonhealth.com/' }
    case 'app.impruvon-staging.com':
      return { sentryEnvironment: 'staging', mobileUrl: 'https://m.impruvon-staging.com/' }
    default:
      return { sentryEnvironment: 'development', mobileUrl: 'https://m.impruvon-staging.com/' }
  }
}

function withApp(Component, { disableActiveAdminStyles = true } = {}) {
  return function Application({
    currentUser, currentOrganization, capabilities, featureFlags, ...rest
  }) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: getEnvironment().sentryEnvironment,
      tracesSampleRate: 1.0,
    })
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email,
        phone: currentUser.phone,
        role: currentUser.role,
      })
    }
    if (currentOrganization) {
      Sentry.setContext('organization', {
        id: currentOrganization.id,
        name: currentOrganization.name,
      })
    }
    return (
      <Sentry.ErrorBoundary fallback="An error has occurred">
        <CurrentUserProvider currentUser={camelizeKeys(currentUser)}>
          {
            currentUser
            && <UserSession currentUser={camelizeKeys(currentUser)} />
          }

          <CurrentOrganizationProvider
            currentOrganization={camelizeKeys(currentOrganization)}
          >
            <CurrentCapabilitiesProvider
              capabilities={camelizeKeys(capabilities)}
            >
              <FeatureFlagsProvider featureFlags={camelizeKeys(featureFlags)}>
                <App disableActiveAdminStyles={disableActiveAdminStyles}>
                  <Component {...rest} />
                </App>
              </FeatureFlagsProvider>
            </CurrentCapabilitiesProvider>
          </CurrentOrganizationProvider>
        </CurrentUserProvider>
      </Sentry.ErrorBoundary>
    )
  }
}

export {
  withApp,
  useCurrentUser,
  useCurrentOrganization,
  useCurrentCapabilities,
  useFeatureFlags,
  getEnvironment,
}

export default {}
