import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { confirmDialog } from 'primereact/confirmdialog'
import CheckboxWithLabel from '@components/display/CheckboxWithLabel'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { useUpdatePatientInsurance, useDeletePatientInsurance } from './patientInsurancesHooks'

function EditPatientInsuranceDialog({
  patient,
  insurance,
  visible,
  onHide,
}) {
  const statusMessage = useRef(null)
  const [insuranceGroupNumber, setInsuranceGroupNumber] = useState(insurance.groupNumber)
  const [insuranceName, setInsuranceName] = useState(insurance.name)
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState(insurance.policyNumber)
  const [insuranceDeactivated, setInsuranceDeactivated] = useState(!!insurance.deactivatedAt)
  const [insuranceDeactivatedAt, setInsuranceDeactivatedAt] = useState(null)

  const {
    mutateAsync: updateInsurance,
    isLoading: isUpdatingInsurance,
  } = useUpdatePatientInsurance(statusMessage)

  const {
    mutateAsync: deleteInsurance,
    isLoading: isDeletingInsurance,
  } = useDeletePatientInsurance(statusMessage)

  const onUpdateInsurance = () => {
    const newInsurance = {
      id: insurance.id,
      patientId: patient.id,
      groupNumber: insuranceGroupNumber,
      name: insuranceName,
      policyNumber: insurancePolicyNumber,
      deactivatedAt: insuranceDeactivatedAt,
    }
    updateInsurance(
      newInsurance,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const onDeleteInsurance = () => {
    deleteInsurance(
      {
        id: insurance.id,
      },
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <h3 className="font-semibold text-lg flex flex-column gap-2">
        {insurance.name}
        {' '}
        -
        {' '}
        {insurance.groupNumber}
        {' '}
        -
        {' '}
        {insurance.policyNumber}
      </h3>
      <Button
        icon="pi pi-trash"
        className="p-button-outlined p-button-text"
        type="button"
        loading={isDeletingInsurance}
        style={{ color: 'var(--red-500)' }}
        onClick={() => confirmDialog({
          message: 'Are you sure you want to delete this insurance?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => onDeleteInsurance(),
        })}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column gap-2 align-items-start px-2">
        <InputTextWithLabel
          label="Name"
          inputId="insurance_name"
          value={insuranceName}
          name="insurance_name"
          onChange={(e) => setInsuranceName(e.target.value)}
        />
        <InputTextWithLabel
          label="Group number"
          inputId="insurance_group_number"
          value={insuranceGroupNumber}
          name="insurance_group_number"
          onChange={(e) => setInsuranceGroupNumber(e.target.value)}
        />
        <InputTextWithLabel
          label="Policy number"
          inputId="insurance_policy_number"
          value={insurancePolicyNumber}
          name="insurance_policy_number"
          onChange={(e) => setInsurancePolicyNumber(e.target.value)}
        />
        <CheckboxWithLabel
          label="Deactivated"
          checked={insuranceDeactivated}
          inputId="insurance_deactivated_at"
          name="insurance_deactivated_at"
          onChange={(e) => {
            setInsuranceDeactivated(e.target.value)
            if (e.target.value) {
              setInsuranceDeactivatedAt(new Date().toISOString())
            } else {
              setInsuranceDeactivatedAt(null)
            }
          }}
        />
        <Button
          label="Update"
          className="flex w-full p-button-sm"
          loading={isUpdatingInsurance}
          onClick={onUpdateInsurance}
          disabled={!insuranceName || !insuranceGroupNumber || !insurancePolicyNumber}
        />
      </div>
    </Dialog>
  )
}

export default EditPatientInsuranceDialog
