import { formatDateTimeLocale, getAdherenceStatusColor, getCssVariable } from '@services/utils'
import moment from 'moment'
import { orderBy } from 'lodash'
import { marHistoryStatusLabels } from '../../config'

const administrationEventItem = (administrationEvent, timezone) => {
  const {
    createdAt, recordedAt, subject, note, code,
  } = administrationEvent

  const oppositeContent = marHistoryStatusLabels[administrationEvent.code] || 'Unknown'

  return ({
    content: {
      title: subject?.fullName,
      subtitles: [
        `Recorded: ${formatDateTimeLocale(moment(createdAt), timezone)}`,
        `Time: ${formatDateTimeLocale(moment(recordedAt), timezone)}`,
        note && `Note: ${note}`,
      ],
    },
    time: moment(recordedAt),
    markerColor: getAdherenceStatusColor(code),
    oppositeContent,
  })
}

const calculateLateByImpactCheck = (completedAt, willBeMissedAt) => {
  const minutesPassed = moment(completedAt).diff(moment(willBeMissedAt), 'minutes')
  const getTimeString = (value, unit) => `${value} ${unit}${value === 1 ? '' : 's'}`

  return minutesPassed >= 60 ? getTimeString(Math.floor(minutesPassed / 60), 'hour')
    : getTimeString(minutesPassed, 'minute')
}

const impactCheckItem = (impactCheck, timezone) => {
  const {
    completedAt, willBeMissedAt, completedBy, status, feedback, effective, expectedAt,
  } = impactCheck

  const title = completedBy?.fullName ?? 'Expected'

  const subtitles = []
  if (completedAt) {
    subtitles.push(formatDateTimeLocale(moment(completedAt), timezone))
    if (status === 'late') {
      subtitles.push(`(Late by ${calculateLateByImpactCheck(completedAt, willBeMissedAt)})`)
    }
    if (feedback) {
      subtitles.push(`Feedback: ${feedback}`)
    }
  } else {
    subtitles.push(formatDateTimeLocale(moment(willBeMissedAt), timezone))
  }

  let oppositeContent
  if (completedAt) {
    oppositeContent = effective ? 'Effective impact' : 'Ineffective impact'
  } else {
    oppositeContent = status === 'missed' ? 'Missed impact check' : null
  }

  let color = getCssVariable('--circle-pending')
  if (completedAt && effective) {
    color = getCssVariable('--text-color')
  }

  return ({
    content: {
      title,
      subtitles,
    },
    time: moment(completedAt ?? expectedAt),
    markerColor: color,
    oppositeContent,
  })
}

/**
 * Returns the timeline items from the consumption object.
 *
 * @param {consumption: Object} consumption - The consumption object
 * @returns {timelineItems: Array} timelineItems - The timeline items
 * timelineItems format:
 * {
 *   content: {
 *     title: string,
 *     subtitles: string[],
 *   },
 *   oppositeContent: string,
 *   markerColor: string,
 *   // Not used for timeline, but used for sorting
 *   time: moment.Moment,
 * }
 */
// eslint-disable-next-line import/prefer-default-export
export const timelineItemsFromConsumption = (consumption) => {
  const { administrationEvents, impactChecks } = consumption

  const timelineItems = []

  administrationEvents.forEach((administrationEvent) => {
    timelineItems.push(administrationEventItem(administrationEvent, consumption.timezone))
  })

  impactChecks.forEach((impactCheck) => {
    timelineItems.push(impactCheckItem(impactCheck, consumption.timezone))
  })

  return orderBy(timelineItems, 'time', 'desc')
}
