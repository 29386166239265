import React, { useState } from 'react'
import DeactivatedUsers from '@components/users/DeactivatedUsers'
import { useDeactivatedPharmacists, useReactivatePharmacist } from '@hooks/pharmacists'
import ReactivateUserDialog from '@components/users/ReactivateUserDialog'

function DeactivatedPharmacists({
  statusMessageRef,
  maxDataViewHeight,
  setSearchLoading,
  searchTerm,
  rootPath,
  footer,
}) {
  const [reactivatedPharmacistId, setReactivatedPharmacistId] = useState()
  const [reactivateDialogVisible, setReactivateDialogVisible] = useState(false)

  const {
    data: deactivatedPharmacists,
    isLoading: isLoadingDeactivatedPharmacists,
  } = useDeactivatedPharmacists()

  const {
    mutateAsync: reactivatePharmacist,
    isLoading: isReactivatePharmacistLoading,
  } = useReactivatePharmacist(statusMessageRef)

  const reactivateDialog = (handleReactivate) => (
    <ReactivateUserDialog
      statusMessageRef={statusMessageRef}
      userRole="pharmacist"
      userId={reactivatedPharmacistId}
      visible={reactivateDialogVisible}
      onHide={() => setReactivateDialogVisible(false)}
      onConfirm={handleReactivate}
      isLoading={isReactivatePharmacistLoading}
    />
  )

  return (
    <DeactivatedUsers
      users={deactivatedPharmacists}
      isLoading={isLoadingDeactivatedPharmacists}
      searchTerm={searchTerm}
      setSearchLoading={setSearchLoading}
      maxDataViewHeight={maxDataViewHeight}
      reactivateUser={reactivatePharmacist}
      isReactivateUserLoading={isReactivatePharmacistLoading}
      reactivatedUserId={reactivatedPharmacistId}
      setReactivatedUserId={setReactivatedPharmacistId}
      rootPath={rootPath}
      showReactivateDialog
      reactivateDialog={reactivateDialog}
      setReactivateDialogVisible={setReactivateDialogVisible}
      footer={footer}
    />
  )
}

export default DeactivatedPharmacists
