import moment from 'moment-timezone'
import {
  camelCase, get, groupBy, isEmpty, update, upperFirst,
} from 'lodash'
import { enumerateDaysBetweenMoments } from '@services/utils'
import { momentFormats } from '@services/utils/moment'

export function formatDashboardItems(vitalsDashboardItems, timezone) {
  if (!vitalsDashboardItems) return []
  return vitalsDashboardItems.map((dashboardItem) => {
    const conditionChecks = groupBy(dashboardItem.conditionChecks, ({ expectedAt, actionedAt }) => (
      moment(expectedAt || actionedAt).tz(timezone).format(momentFormats.date)
    ))
    const mappedAdHocMeasurements = dashboardItem.adhocMeasurements.map((measurement) => (
      {
        ...measurement,
        measurementType: upperFirst(camelCase(dashboardItem.measurementType)),
        adhoc: true,
      }
    ))
    const adhocMeasurements = groupBy(mappedAdHocMeasurements, ({ createdAt }) => (
      moment(createdAt).tz(timezone).format(momentFormats.date)
    ))
    return { ...dashboardItem, conditionChecks, adhocMeasurements }
  })
}

export function vitalsDashboardColumns(
  {
    dateRange,
    measurementTypeTemplate,
    conditionCheckTemplate,
  },
) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'measurement_type',
      header: 'Measurement Type',
      body: measurementTypeTemplate,
      style: { minWidth: '15rem', width: '15rem', maxWidth: '15rem' },
      frozen: true,
    },
  ]

  days.forEach((day) => {
    columns.push({
      field: day.format(momentFormats.date),
      header: day.format('MMM Do'),
      body: conditionCheckTemplate,
      style: { minWidth: '10rem', width: '10rem', maxWidth: '10rem' },
    })
  })

  return columns
}

export function groupVitalsByStatus(conditionChecks, adhocMeasurements) {
  const groupedConditionChecks = groupBy(conditionChecks, ({ status }) => (status), {})
  if (isEmpty(adhocMeasurements)) return groupedConditionChecks

  update(groupedConditionChecks, 'completed', (checks = []) => [...checks, ...adhocMeasurements])
  return groupedConditionChecks
}

export function temperatureUnitDisplay(temperatureUnit) {
  switch (temperatureUnit) {
    case 'fahrenheit':
      return '°F'
    case 'celsius':
      return '°C'
    default:
      return ''
  }
}

export function vitalValueDisplay(vital) {
  const measurement = vital.adhoc ? vital : vital[camelCase(vital.measurementType)]

  switch (vital.measurementType) {
    case 'BloodPressure':
      return `${measurement.systolic}/${measurement.diastolic} mmHg`
    case 'BloodGlucose':
      return `${measurement.value} ${measurement.unit}`
    case 'Temperature':
      return `${measurement.value}${temperatureUnitDisplay(measurement.unit)}`
    case 'Pulse':
      return `${measurement.bpm} bpm`
    case 'BloodOxygen':
      return `${measurement.value}%`
    case 'Height':
      return `${measurement.value} ${measurement.unit}`
    case 'Weight':
      return `${measurement.value} ${measurement.unit}`
    default:
      return ''
  }
}

export function conditionCheckTakenBy(conditionCheck) {
  return get(conditionCheck, `${camelCase(conditionCheck.measurementType)}.takenBy`)
}

export function conditionCheckTakenAt(conditionCheck) {
  return get(conditionCheck, `${camelCase(conditionCheck.measurementType)}.createdAt`)
}
