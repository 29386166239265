import React from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { isMonthYearOptionAfterOrSameAsNow } from '@services/utils/moment'

function BlankReportSwitch({
  reportType,
  selectedMonthYearStart,
  blankReport,
  setBlankReport,
}) {
  if (!(['mar', 'tar'].includes(reportType) && selectedMonthYearStart)) {
    return null
  }
  if (!isMonthYearOptionAfterOrSameAsNow(selectedMonthYearStart)) {
    return null
  }

  return (
    <>
      <span className="block text-sm text-gray-500 mb-2 mt-2">Blank Report</span>
      <InputSwitch
        checked={blankReport}
        onChange={(e) => setBlankReport(e.value)}
      />
    </>
  )
}

export default BlankReportSwitch
