import React, { useState } from 'react'
import { Timeline } from 'primereact/timeline'
import HistoryContent from './HistoryContent'
import HistoryOppositeContent from './HistoryOppositeContent'
import HistoryMarker from './HistoryMarker'

/**
 * Display a timeline with the given items, by default the timeline is hidden.
 * If there are no items, the component will not render anything.
 *
 * @param {timelineItems: Array} timelineItems - Array of timeline items, the format of each item is
 * {
 *   id: string,
 *   content: {
 *     title: string,
 *     subtitles: string[],
 *   },
 *   oppositeContent: string,
 *   markerColor: string,
 * }
 * @returns {React.ReactNode}
 */
function ConsumptionHistory({ timelineItems = [] }) {
  const [showHistory, setShowHistory] = useState(false)

  const toggleHistory = () => {
    setShowHistory(!showHistory)
  }

  if (timelineItems.length === 0) {
    return null
  }

  const itemContent = (item) => (
    <HistoryContent title={item.content?.title} subtitles={item.content?.subtitles} />
  )
  const itemOppositeContent = (item) => (
    <HistoryOppositeContent content={item.oppositeContent} />
  )
  const itemMarker = (item) => <HistoryMarker color={item.markerColor} />

  return (
    <div>
      <div
        className="flex flex-row align-items-center cursor-pointer mt-1 w-4rem"
        onClick={toggleHistory}
        onKeyDown={(e) => e.key === 'Enter' && toggleHistory()}
        role="button"
        tabIndex={0}
      >
        <div className="text-xs font-medium font-light text-primary">
          Details
        </div>
        <i className={`text-primary text-sm ${showHistory ? 'pi pi-angle-up' : 'pi pi-angle-down'}`} />
      </div>
      {showHistory && (
        <div className="card px-0">
          <Timeline
            className="flex flex-column align-items-start w-full"
            value={timelineItems}
            align="left"
            content={itemContent}
            opposite={itemOppositeContent}
            marker={itemMarker}
          />
        </div>
      )}
    </div>
  )
}

export default ConsumptionHistory
