import React, { useState, useEffect, useRef } from 'react'
import {
  isEmpty, first, sortBy, get,
} from 'lodash'
import { Fieldset } from 'primereact/fieldset'
import { Timeline } from 'primereact/timeline'
import { formatAdministrationAmount } from '@components/clientDoses/doseUtils'
import moment from 'moment'
import { momentFormats, momentTzWithoutChangingTime, momentToLocalWithoutChangingTime } from '@services/utils/moment'
import AdministrationAmount from './AdministrationAmount'
import MedicinePackaging from './Packaging'
import PackageQuantity from './PackageQuantity'
import Instructions from './Instructions'
import StartAtDate from './StartAtDate'
import DeactivatedAtDate from './DeactivatedAt'
import ExpirationDate from './ExpirationDate'
import NumberOfRefills from './NumberOfRefills'
import AdministrationRoute from './AdministrationRoute'
import { administrationRoutes } from '../config'

function DosingFieldset({
  patient, dose, medicine, isEdit, setActiveField, handleCompletion, layoutRef,
}) {
  const dosingFieldsetRef = useRef(null)
  const [activeStep, setActiveStep] = useState()
  const [doseAmount, setDoseAmount] = useState(1)
  const [doseUnits, setDoseUnits] = useState()
  const [administrationRoute, setAdministrationRoute] = useState()
  const [doseAmountDisplay, setDoseAmountDisplay] = useState()
  const [medicinePackaging, setMedicinePackaging] = useState()
  const [dosesInFill, setDosesInFill] = useState()
  const [instructions, setInstructions] = useState()
  const [startAtMoment, setStartAtMoment] = useState(moment())
  const [deactivatedAt, setDeactivatedAt] = useState()
  const [expirationDate, setExpirationDate] = useState()
  const [refillsRemaining, setRefillsRemaining] = useState()
  const { timezone } = patient

  const steps = [
    { label: 'Administration Amount', value: doseAmount },
    { label: 'Administration Route', value: get(administrationRoutes[administrationRoute], 'name') },
    { label: 'Packaging', value: medicinePackaging },
    { label: 'Doses in Package', value: dosesInFill },
    { label: 'Instructions', value: instructions },
    { label: 'Start At Date', value: startAtMoment },
    { label: 'End At Date', value: deactivatedAt },
    { label: 'Expiration Date', value: expirationDate },
    { label: 'Number of Refills', value: refillsRemaining },
  ]

  const handleFieldSelection = (index) => {
    setActiveField('dosing')
    setActiveStep(index)
  }

  const fieldMarker = (item, index) => {
    let color = '#dee2e6'
    let icon = 'pi pi-stop'

    let { value } = item
    if (item.label === 'Administration Amount') {
      value = doseAmountDisplay
    }

    if (activeStep === index) {
      color = '#fa8e42'
      icon = 'pi pi-file-edit'
    } else if (value) {
      color = '#0bda51'
      icon = 'pi pi-check-square'
    }

    return (
      <span onClick={() => handleFieldSelection(index)} className="cursor-pointer p-1" style={{ color }}>
        <i className={icon} />
      </span>
    )
  }

  const isDateField = (item) => ['Start At Date', 'End At Date', 'Expiration Date'].includes(item.label)

  const fieldContent = (item, index) => {
    const getTextColor = () => (activeStep === index ? 'text-color' : 'text-color-secondary')

    let { value } = item
    if (item.label === 'Administration Amount') {
      value = doseAmountDisplay
    }

    if (isDateField(item) && item.value) {
      value = moment(item.value).tz(timezone).format(momentFormats.dateYear)
    }

    let maxWidth = 'inherit'
    if (item.label === 'Instructions') {
      maxWidth = '12rem'
    }

    return (
      <div onClick={() => handleFieldSelection(index)} className={`cursor-pointer flex flex-column pt-1 gap-2 ${getTextColor()}`}>
        <span className="text-base">{item.label}</span>
        <span className="text-sm" style={{ maxWidth }}>{value}</span>
      </div>
    )
  }

  const handleLastStep = () => {
    setActiveStep(null)
    handleCompletion({
      administrationAmount: doseAmount,
      doseUnits,
      administrationRoute,
      medicinePackaging,
      dosesInFill,
      instructions,
      startAtDate: momentTzWithoutChangingTime(startAtMoment, timezone).toISOString(),
      deactivatedAtDate: deactivatedAt
        ? momentTzWithoutChangingTime(deactivatedAt, timezone).toISOString() : null,
      expirationDate,
      refillsRemaining,
    })
  }

  const calculateExpirationDate = () => {
    const fillWithMostInventory = first(sortBy(dose.doseFills, 'dosesInFill', 'asc'))
    return fillWithMostInventory ? fillWithMostInventory.expiresAt : dose.endAt
  }

  useEffect(() => {
    if (isEdit) {
      setActiveStep(0)
      const layout = layoutRef.current
      const element = dosingFieldsetRef.current
      const scrollTo = element.scrollHeight
      layout.scrollTo({ top: scrollTo, behavior: 'smooth' })
    } else {
      setActiveStep(null)
    }
  }, [isEdit])

  useEffect(() => {
    if (!isEmpty(dose)) {
      setDosesInFill(dose.quantity)
      setDoseAmount(dose.administrationAmount)
      setDoseUnits(dose.units)
      setMedicinePackaging(dose.packaging)
      setStartAtMoment(dose.startAt
        ? momentToLocalWithoutChangingTime(moment(dose.startAt), timezone) : moment())
      setDeactivatedAt(dose.discontinuedAt
        ? momentToLocalWithoutChangingTime(moment(dose.discontinuedAt), timezone) : null)
      setExpirationDate(new Date(calculateExpirationDate()))
      setAdministrationRoute(dose.administrationRoute)
    }
  }, [dose])

  useEffect(() => {
    if (medicine?.strength && medicine?.form && doseAmount) {
      const display = formatAdministrationAmount({
        administrationAmount: doseAmount,
        medicine,
      })
      setDoseAmountDisplay(display)
    }
  }, [medicine, doseAmount])

  return (
    <Fieldset legend="Dosing">
      <div ref={dosingFieldsetRef} className="flex flew-row">
        { isEmpty(medicine)
          && <div>Please select and save a medicine first.</div>}
        { !isEmpty(medicine)
          && (
          <>
            <Timeline
              style={{ minWidth: '16em' }}
              value={steps}
              marker={fieldMarker}
              content={fieldContent}
            />
            { activeStep === 0
            && (
            <AdministrationAmount
              doseAmount={doseAmount}
              doseAmountDisplay={doseAmountDisplay}
              setDoseAmount={setDoseAmount}
              medicine={medicine}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 1
            && (
            <AdministrationRoute
              administrationRoute={administrationRoute}
              setAdministrationRoute={setAdministrationRoute}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 2
            && (
            <MedicinePackaging
              medicineForm={medicine.form}
              medicinePackaging={medicinePackaging}
              setMedicinePackaging={setMedicinePackaging}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 3
            && (
            <PackageQuantity
              dosesInFill={dosesInFill}
              setDosesInFill={setDosesInFill}
              medicineForm={medicine.form}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 4
            && (
            <Instructions
              instructions={instructions}
              setInstructions={setInstructions}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 5
            && (
            <StartAtDate
              startAtMoment={momentToLocalWithoutChangingTime(startAtMoment, timezone)}
              setStartAtMoment={(date) => {
                if (!date) {
                  setStartAtMoment(null)
                  return
                }
                const startToSet = momentTzWithoutChangingTime(moment(date).startOf('day'), timezone)
                return setStartAtMoment(startToSet)
              }}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 6
            && (
            <DeactivatedAtDate
              deactivatedAt={momentToLocalWithoutChangingTime(deactivatedAt, timezone)}
              setDeactivatedAt={(date) => {
                if (!date) {
                  setDeactivatedAt(null)
                  return
                }
                const endToSet = momentTzWithoutChangingTime(moment(date).endOf('day'), timezone)
                return setDeactivatedAt(endToSet)
              }}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 7
            && (
            <ExpirationDate
              expirationDate={expirationDate}
              setExpirationDate={setExpirationDate}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
            { activeStep === 8
            && (
            <NumberOfRefills
              refillsRemaining={refillsRemaining}
              setRefillsRemaining={setRefillsRemaining}
              handleConfirm={handleLastStep}
            />
            )}
          </>
          )}
      </div>
    </Fieldset>
  )
}

export default DosingFieldset
