import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useDeactivatedPharmacists() {
  return useQuery({
    queryKey: ['deactivatedPharmacists'],
    queryFn: () => (
      ApiClient.get('/pharmacists/deactivated')
    ),
    placeholderData: [],
    select: (data) => data?.pharmacists || [],
  })
}

export default useDeactivatedPharmacists
