import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import PatientAvatarThumbnail from '@components/patients/PatientList/PatientAvatarThumbnail'
import { exactSearch } from '@services/utils/search'

function PatientOrders({
  patients,
  isFetching,
  searchTerm,
  setSearchLoading,
  rootPath,
  maxDataViewHeight,
  setFooter,
  selectedSites,
}) {
  const navigate = useNavigate()
  const [filteredPatients, setFilteredPatients] = useState([])
  const organization = useCurrentOrganization()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setSearchLoading(true)
    const patientsWithOrders = patients.filter((patient) => patient.numReviewableOrders > 0)
    const siteFilteredPatients = selectedSites?.length > 0
      ? patientsWithOrders.filter((patient) => selectedSites.includes(get(patient, 'site.id')))
      : patientsWithOrders

    if (searchTerm) {
      setFilteredPatients(exactSearch(siteFilteredPatients, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPatients(siteFilteredPatients)
    }
    setSearchLoading(false)
  }, [isFetching, searchTerm, selectedSites])

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isFetching ? `${organization.patientLabelPlural} Count: ${filteredPatients.length}` : ''}
    </span>
  )

  useEffect(() => {
    setFooter(footTemplate)
  }, [filteredPatients])

  const pharmacyOrdersTemplate = ({ id, numReviewableOrders }) => (
    <Tag
      severity="warning"
      className="p-mr-2"
      onClick={(e) => {
        navigate(`${rootPath}/${id}/orders/needs-review`)
        e.stopPropagation()
      }}
    >
      {`${numReviewableOrders} PENDING`}
    </Tag>
  )

  return (
    <div>
      <div className="flex flex-column gap-3 text-base text-900">
        <DataTable
          dataKey="id"
          loading={isFetching}
          value={filteredPatients}
          selectionMode="single"
          onSelectionChange={(e) => {
            navigate(`${rootPath}/${e.value.id}/orders/needs-review`)
          }}
          sortField={searchParams.get('sortField') || 'lastName'}
          sortOrder={searchParams.get('sortOrder') || 1}
          rowClassName="fadein patient-row"
          scrollable
          scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
        >
          <Column headerStyle={{ width: '3rem' }} body={PatientAvatarThumbnail} />
          <Column header="First Name" field="firstName" />
          <Column header="Last Name" field="lastName" sortable />
          <Column
            header="Orders"
            field="numReviewableOrders"
            body={pharmacyOrdersTemplate}
            sortable
          />
          <Column header="Room #" field="roomNumber" sortable />
        </DataTable>
      </div>
    </div>
  )
}

export default PatientOrders
