import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import Autocomplete from '@components/display/Autocomplete'
import { usePrescriptions } from '@hooks/prescriptions'
import { useCreateSmartFlip } from '@hooks/smartFlips'

function CreateSmartFlipDialog({
  prescription, prescriptionType, isVisible, onHide, messagesRef,
}) {
  const [selectedPrescription, setSelectedPrescription] = useState(null)

  const {
    data: prescriptions,
    isLoading: isLoadingPrescriptions,
  } = usePrescriptions({ model: 'Patient', id: prescription?.patientId })

  const {
    mutateAsync: createSmartFlip,
    isLoading,
  } = useCreateSmartFlip(messagesRef)

  if (!prescription || isLoadingPrescriptions) {
    return null
  }

  const onCreate = async () => {
    await createSmartFlip({
      fromPrescriptionId: prescriptionType === 'from' ? prescription.id : selectedPrescription.id,
      toPrescriptionId: prescriptionType === 'to' ? prescription.id : selectedPrescription.id,
    })
    onHide()
  }

  const prescriptionTemplate = (smartFlipType) => {
    if (prescriptionType === smartFlipType) {
      return (
        <span className="text-lg">
          {prescription.prescriptionNumber}
        </span>
      )
    }

    return (
      <Autocomplete
        options={prescriptions}
        displayProperty="prescriptionNumber"
        onOptionSelect={setSelectedPrescription}
        placeholder="Search prescriptions..."
      />
    )
  }

  return (
    <Dialog
      header="Create Smart Flip"
      visible={isVisible}
      onHide={onHide}
      style={{ width: '50vw' }}
      dismissableMask
    >
      <div className="flex flex-column gap-1">
        <div className="flex flex-row gap-2 align-items-center">
          <i className="pi pi-exclamation-triangle text-orange-500" style={{ fontSize: '2rem' }} />
          <span className="text-lg">
            Smart flip from
          </span>
          { prescriptionTemplate('from') }
          <span className="text-lg">
            to
          </span>
          { prescriptionTemplate('to') }
        </div>
        <div className="flex flex-row gap-2 justify-content-end">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={onHide}
            disabled={isLoading}
          />
          <Button
            label="Create"
            onClick={onCreate}
            loading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateSmartFlipDialog
