import React from 'react'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'

function DeactivatedAtDate({ deactivatedAt, setDeactivatedAt, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-end-date">
          When does this medication regimen end?
        </label>
        <Calendar
          inputId="dose-end-date"
          data-testid="end-at-date"
          value={deactivatedAt?.toDate()}
          showIcon
          minDate={new Date()}
          onChange={(e) => setDeactivatedAt(e.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
        />
      </div>
    </div>
  )
}

export default DeactivatedAtDate
