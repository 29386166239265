import React from 'react'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import CheckboxWithLabel from '@components/display/CheckboxWithLabel'

function ProfileForm({ profile = {}, setProfile, isSsoOnboarding }) {
  const {
    email, initials, phone, firstName, lastName, customerId, isDirectoryManaged = false,
    restrictSiteAccess = false,
  } = profile

  const currentUser = useCurrentUser()
  const currentOrganization = useCurrentOrganization()
  const isSuperAdminOrSiteAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const showRestrictSiteAccess = isSuperAdminOrSiteAdmin && currentOrganization.deviceBasedAccess

  return (
    <div className="flex flex-column">
      {
        isDirectoryManaged && (
          <div className="text-sm opacity-80 mb-3">
            Disabled settings are managed by your organization's IT administrators.
          </div>
        )
      }

      <InputTextWithLabel
        label="Initials"
        value={initials || ''}
        inputId="initials"
        name="initials"
        onChange={({ target: { value } }) => setProfile({ initials: value.toUpperCase() })}
      />

      <InputTextWithLabel
        label="Email"
        value={email || ''}
        inputId="email"
        name="email"
        disabled={isSsoOnboarding || isDirectoryManaged}
        onChange={({ target: { value } }) => setProfile({ email: value })}
      />
      <InputTextWithLabel
        label="Phone"
        value={phone || ''}
        inputId="phone"
        name="phone"
        onChange={({ target: { value } }) => setProfile({ phone: value })}
      />
      <InputTextWithLabel
        label="First Name"
        value={firstName || ''}
        inputId="firstName"
        name="firstName"
        disabled={isSsoOnboarding || isDirectoryManaged}
        onChange={({ target: { value } }) => setProfile({ firstName: value })}
      />
      <InputTextWithLabel
        label="Last Name"
        value={lastName || ''}
        inputId="lastName"
        name="lastName"
        disabled={isSsoOnboarding || isDirectoryManaged}
        onChange={({ target: { value } }) => setProfile({ lastName: value })}
      />
      <InputTextWithLabel
        label="Employee ID"
        value={customerId || ''}
        inputId="employeeId"
        name="employeeId"
        onChange={({ target: { value } }) => setProfile({ customerId: value })}
      />
      {
        showRestrictSiteAccess && (
          <CheckboxWithLabel
            label="Restrict Site Access"
            checked={restrictSiteAccess}
            inputId="restrictSiteAccess"
            name="restrictSiteAccess"
            onChange={({ target: { value } }) => setProfile({ restrictSiteAccess: value })}
          />
        )
      }
    </div>
  )
}

export default ProfileForm
