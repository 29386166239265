import React, { useState } from 'react'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import PatientAvatarThumbnail from '@components/patients/PatientList/PatientAvatarThumbnail'
import DeactivatedUsers from '@components/users/DeactivatedUsers'
import ReactivateUserDialog from '@components/users/ReactivateUserDialog'
import { useDeactivatedPatients, useReactivatePatient } from './patientHooks'

function DeactivatedPatients({
  statusMessage,
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  rootPath,
  footer,
}) {
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const [reactivatePatientId, setReactivatePatientId] = useState()
  const [reactivateDialogVisible, setReactivateDialogVisible] = useState(false)

  const {
    data: deactivatedPatients,
    isFetching: isDeactivatedPatientsFetching,
  } = useDeactivatedPatients(organization.id, statusMessage, currentUser)

  const {
    mutateAsync: reactivatePatient,
    isLoading: isReactivatePatientLoading,
  } = useReactivatePatient(statusMessage, organization.patientLabelSingular)

  const reactivateDialog = (handleReactivate) => (
    <ReactivateUserDialog
      statusMessageRef={statusMessage}
      organization={organization}
      userId={reactivatePatientId}
      visible={reactivateDialogVisible}
      onHide={() => setReactivateDialogVisible(false)}
      onConfirm={handleReactivate}
      isLoading={isReactivatePatientLoading}
    />
  )

  return (
    <DeactivatedUsers
      users={deactivatedPatients}
      isLoading={isDeactivatedPatientsFetching}
      searchTerm={searchTerm}
      setSearchLoading={setSearchLoading}
      maxDataViewHeight={maxDataViewHeight}
      reactivateUser={reactivatePatient}
      isReactivateUserLoading={isReactivatePatientLoading}
      reactivatedUserId={reactivatePatientId}
      setReactivatedUserId={setReactivatePatientId}
      rootPath={rootPath}
      showReactivateDialog
      reactivateDialog={reactivateDialog}
      setReactivateDialogVisible={setReactivateDialogVisible}
      avatarThumbnail={PatientAvatarThumbnail}
      showNoteColumn
      footer={footer}
    />
  )
}

export default DeactivatedPatients
