import React, { useMemo, useCallback, useEffect } from 'react'
import { compact, get } from 'lodash'
import { Route, Routes, useParams } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'
import CaregiverList from '@components/patients/CaregiverList'
import GuardianList from '@components/patients/GuardianList'
import PatientDashboard from '@components/patients/PatientDashboard'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { usePageOutlet as usePageLayoutContext } from '@components/display/PageLayout'
import ProfileOverview from '@components/patients/Profile/ProfileOverview'
import ClientDoses from '../ClientDoses'
import ClientTasks from '../ClientTasks'
import UpdatePatient from './UpdatePatient'
import EventTimes from '../Schedules/EventTimes'
import { usePatient } from './patientHooks'
import HealthRecords from './HealthRecords'
import HRST from './HRST'
import Treatments from '../Treatments'
import Orders from './Orders'
import MatchingUsers from './MatchingUsers'
import Prescriptions from '../Prescriptions/Prescriptions'

function Patient() {
  const { patientId } = useParams()
  const {
    setHeader, maxDataViewHeight, layoutRef, rootPath,
  } = usePageLayoutContext()
  const { data: patient, isLoading } = usePatient(
    patientId,
  )
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.role !== 'superadmin') return
    if (patient && patient.organizationId !== organization.id) {
      window.location.href = `${window.location.pathname}?organization_id=${get(patient, 'organizationId')}`
    }
  }, [patient, organization])

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const title = useMemo(() => ({
    label: (
      <span className="flex flex-row align-items-center" id="patient-name-tab">
        <i className="text-xl pi pi-user mr-2" />
        {isLoading || !patient ? (
          <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
        ) : (
          <>
            <span className="fadein">
              {`${get(patient, 'firstName')} ${get(patient, 'lastName')}`}
            </span>
            {get(patient, 'currentPause') && (
              <Tag className="ml-2" value="LOA" severity="warning" />
            )}
            {get(patient, 'isActive') === false && (
              <Tag className="ml-2" value="Deactivated" severity="danger" />
            )}
          </>
        )}
      </span>
    ),
    linkTo: `${rootPath}/${patientId}`,
  }), [isLoading, patient, rootPath, patientId])

  const showHrst = organization.patientHrstEnabled && (currentUser.role === 'superadmin' || currentUser.role === 'site_admin')
  const showTreatments = patient && organization.treatmentsEnabled && isCurrentUserAdmin
  const showTasks = showTreatments
  const showPrescriptions = patient && currentUser.role === 'superadmin'

  const numReviewableOrders = get(patient, 'numReviewableOrders')

  const tabs = compact([
    {
      label: 'Profile',
      linkTo: `${patientId}/profile`,
    },
    !organization.ccmEnabled && isCurrentUserAdmin && {
      label: 'Medications',
      linkTo: `${patientId}/doses`,
    },
    showHrst && {
      label: 'HRST',
      linkTo: `${patientId}/hrst/demographics`,
    },
    !organization.ccmEnabled && isCurrentUserAdmin && {
      label: 'Orders',
      linkTo: `${patientId}/orders/needs-review`,
      badge: numReviewableOrders && { severity: 'warning', value: numReviewableOrders },
    },
    showTreatments && {
      label: 'Treatments',
      linkTo: `${patientId}/treatments`,
    },
    showTasks && {
      label: 'Tasks',
      linkTo: `${patientId}/tasks`,
    },
    {
      label: 'Health Records',
      linkTo: `${patientId}/health_records/clinical_notes`,
    },
    isCurrentUserAdmin && !organization.deviceBasedAccess && {
      label: organization.caregiverLabelPlural,
      linkTo: `${patientId}/caregivers`,
    },
    isCurrentUserAdmin && {
      label: 'Scheduled Events',
      linkTo: `${patientId}/event_times`,
    },
    isCurrentUserAdmin && organization.guardiansEnabled && {
      label: 'Guardians',
      linkTo: `${patientId}/guardians`,
    },
    showPrescriptions && {
      label: 'Prescriptions',
      linkTo: `${patientId}/prescriptions`,
    },
  ])

  const breadcrumbs = [
    {
      label: 'Patient',
      template: <span>{organization.patientLabelSingular}</span>,
    },
  ]

  const handleSetHeader = useCallback((props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }, [setHeader, title, tabs, breadcrumbs])

  useEffect(() => { handleSetHeader() }, [title])

  return (
    <Routes>
      <Route
        index
        path="/*"
        element={(
          <PatientDashboard
            maxDataViewHeight={maxDataViewHeight}
            patientId={patientId}
            handleSetHeader={handleSetHeader}
            patientLoading={isLoading}
            patient={patient}
            rootPath={rootPath}
          />
        )}
      />
      <Route
        path="profile"
        element={(
          <ProfileOverview
            patient={patient}
            handleSetHeader={handleSetHeader}
            isLoading={isLoading}
            isCurrentUserAdmin={isCurrentUserAdmin}
            rootPath={rootPath}
          />
      )}
      />
      {isCurrentUserAdmin
        && (
          <Route
            path="update"
            element={(
              <UpdatePatient
                rootPath={rootPath}
                patient={patient}
                handleSetHeader={handleSetHeader}
              />
            )}
          />
        )}
      {!organization.ccmEnabled && isCurrentUserAdmin && patient
        && (
          <>
            <Route
              path="doses/*"
              element={(
                <ClientDoses
                  patient={patient}
                  layoutRef={layoutRef}
                  maxDataViewHeight={maxDataViewHeight}
                  handleSetHeader={handleSetHeader}
                />
              )}
            />
            <Route
              path="orders/*"
              element={(
                <Orders
                  patient={patient}
                  handleSetHeader={handleSetHeader}
                />
              )}
            />
          </>
        )}
      { showTreatments && (
        <Route
          path="treatments/*"
          element={(
            <Treatments
              patient={patient}
              layoutRef={layoutRef}
              handleSetHeader={handleSetHeader}
              maxDataViewHeight={maxDataViewHeight}
            />
          )}
        />
      )}
      { showTasks && (
        <Route
          path="tasks/*"
          element={(
            <ClientTasks
              patient={patient}
              layoutRef={layoutRef}
              maxDataViewHeight={maxDataViewHeight}
              handleSetHeader={handleSetHeader}
            />
          )}
        />
      )}
      {isCurrentUserAdmin
        && (
          <Route
            path="caregivers"
            element={<CaregiverList patient={patient} handleSetHeader={handleSetHeader} />}
          />
        )}
      {isCurrentUserAdmin
        && (
          <Route
            path="event_times/*"
            element={<EventTimes ownerType="User" handleSetHeader={handleSetHeader} />}
          />
        )}
      {isCurrentUserAdmin && organization.guardiansEnabled
        && (
          <Route
            path="guardians"
            element={<GuardianList patient={patient} handleSetHeader={handleSetHeader} />}
          />
        )}
      <Route
        path="health_records/*"
        element={<HealthRecords patient={patient} handleSetHeader={handleSetHeader} />}
      />
      <Route
        path="matching_users/*"
        element={<MatchingUsers patient={patient} handleSetHeader={handleSetHeader} />}
      />
      {
        showHrst && (
          <Route
            path="hrst/*"
            element={<HRST patient={patient} handleSetHeader={handleSetHeader} />}
          />
        )
      }
      { showPrescriptions && (
        <Route
          path="prescriptions/*"
          element={(
            <Prescriptions patient={patient} handleSetHeader={handleSetHeader} />
          )}
        />
      )}
    </Routes>
  )
}

export default Patient
