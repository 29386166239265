import {
  flatMap, groupBy, isEmpty, isNumber, uniq,
} from 'lodash'
import { RRule } from 'rrule'
import { titleCase } from '@services/utils'
import pluralize from 'pluralize'
import moment from 'moment-timezone'
import { getMomentFromMinutes, momentFormats } from '@services/utils/moment'

const formatAdministrationAmount = ({ administrationAmount, medicine = {} }) => {
  const form = isEmpty(medicine.form) ? null : pluralize(medicine.form, administrationAmount)

  const parts = [administrationAmount, form]
    .filter((part) => isNumber(part) || !isEmpty(part))
  return parts.join(' ')
}

const consumptionDisplayName = (consumption) => {
  if (!isEmpty(consumption.medicineName)) {
    return consumption.medicineName
  }
  return consumption.medicine.name
}

const medicineDisplayName = (dose) => {
  if (!isEmpty(dose.orderText)) {
    return dose.orderText
  }
  return dose.medicine.name
}

const hasDoseDiscontinued = (dose) => {
  if (!dose.discontinuedAt) {
    return false
  }
  const discontinuedAt = moment(dose.discontinuedAt)
  const now = moment()
  return discontinuedAt.isBefore(now)
}

const consumptionDisplayNameWithStrength = (consumption) => {
  if (!isEmpty(consumption.medicineName)) {
    return consumption.medicineName
  }
  return `${consumption.medicine.name}, ${consumption.medicine.strength}`
}

const medicineDisplayNameWithStrength = (dose) => {
  if (!isEmpty(dose.orderText)) {
    return dose.orderText
  }
  return `${dose.medicine.name}, ${dose.medicine.strength}`
}

const calculateDoseStatus = (dose) => {
  let color; let text; let
    order
  if (dose.activatedAt) {
    if (dose.currentPause) {
      color = 'var(--bluegray-300)'
      text = 'On Hold'
      order = 4
    } else {
      color = 'var(--green-400)'
      text = 'Active'
      order = 2
    }
  }

  if (!dose.activatedAt) {
    color = 'var(--yellow-400)'
    text = 'In Review'
    order = 0
  }

  if (dose.issueReportedAt) {
    color = 'var(--orange-400)'
    text = 'Issue Raised'
    order = 1
  }

  // If it's archived and ended, status should be archived
  if (dose.archivedAt) {
    color = 'var(--bluegray-300)'
    text = 'Archived'
    order = 3
  } else if (hasDoseDiscontinued(dose)) {
    color = 'var(--bluegray-300)'
    text = 'Ended'
    order = 5
  }

  return { color, text, order }
}

const calculateDoseType = (dose) => {
  const doseType = dose.doseType ?? (dose.prn ? 'prn' : 'routine')
  return { color: 'var(--blue-400)', text: doseType }
}

const isPrn = (dose, doseSig = null) => {
  if (typeof doseSig?.prn === 'boolean') {
    return doseSig.prn
  }

  return dose?.doseType === 'prn'
}

const hasPrnSig = (dose) => {
  if (!dose) {
    return false
  }

  if (typeof dose.doseType === 'string') {
    return dose.doseType === 'prn'
  }

  return typeof dose.prn === 'boolean' ? dose.prn : false
}

const hasScheduleSig = (dose) => {
  if (!dose) {
    return false
  }

  if (typeof dose.doseType === 'string') {
    return ['loading', 'routine', 'titration'].includes(dose.doseType)
  }

  return typeof dose.prn === 'boolean' ? !dose.prn : false
}

/**
 * Checks if the given dose has any sigs with schedules
 * @param {object} dose
 * @returns {boolean}
 */
const hasSchedules = (dose) => {
  if (dose && Array.isArray(dose.sigs)) {
    return dose.sigs.some((sig) => Array.isArray(sig.schedules) && sig.schedules.length > 0)
  }

  return false
}

const formattedTextsFromSchedules = (schedules) => {
  const schedulesByRruleText = groupBy(schedules, (schedule) => {
    const { rrule } = schedule
    const parsedRrule = RRule.fromString(rrule)
    return `${parsedRrule.options.freq}`
  })

  const formattedScheduleTexts = flatMap(schedulesByRruleText, (schedulesByRrule) => {
    const { rrule } = schedulesByRrule[0]
    const parsedRrule = RRule.fromString(rrule)

    const times = schedulesByRrule.map((schedule) => {
      const { time } = schedule
      const timeMoment = getMomentFromMinutes(time)
      const formattedTime = timeMoment.format(momentFormats.time_12_trim)
      return formattedTime
    })

    return `${titleCase(parsedRrule.toText())} at ${times.join(', ')}`
  })

  return formattedScheduleTexts
}

/**
 * @param schedules - an array of schedule objects
 * @returns an array containing all the unique event times for all the schedules
 */
const buildAvailableTimes = (schedules) => uniq(schedules.flatMap((schedule) => {
  // Ruby IceCube outputs timezones such as "EST" but not IANA timezones like America/New_York.
  // Since FullCalendar requires IANA but the backend cannot serve it, because we rely on IceCube,
  // we have to convert to UTC below.
  const rruleOptions = RRule.parseString(schedule.rruleForDose)
  rruleOptions.dtstart = moment.utc(rruleOptions.dtstart).toDate()

  if (schedule.doseSig.endAt) {
    rruleOptions.until = moment.utc(rruleOptions.until).toDate()
  }

  rruleOptions.tzid = 'UTC'

  const start = moment.utc(schedule.startAt).tz(rruleOptions.tzid)
  const end = moment.utc(schedule.startAt).add(1, 'days')
  const occurrences = new RRule(rruleOptions).between(start.toDate(), end.toDate())

  //
  // When you take the local time values from the occurrence and convert them to UTC they come
  // out with the values we expect in the original timezone (at least for the cases I've tested).
  //
  // Read more here: https://github.com/jkbrzt/rrule?tab=readme-ov-file#important-use-utc-dates
  //
  return occurrences.map((occurrence) => moment(occurrence).tz(rruleOptions.tzid).format('h:mm a'))
}))

export {
  formatAdministrationAmount,
  calculateDoseStatus,
  calculateDoseType,
  hasDoseDiscontinued,
  hasPrnSig,
  hasScheduleSig,
  hasSchedules,
  isPrn,
  consumptionDisplayName,
  consumptionDisplayNameWithStrength,
  medicineDisplayName,
  medicineDisplayNameWithStrength,
  formattedTextsFromSchedules,
  buildAvailableTimes,
}
