import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useReactivatePharmacist(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['reactivatePharmacist'],
    mutationFn: ({ userId, siteIds, note }) => (
      ApiClient.post(`/pharmacists/${userId}/reactivate`, { siteIds, note })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Pharmacist reactivated!' },
      ])
      queryClient.invalidateQueries('pharmacists')
      queryClient.invalidateQueries('deactivatedPharmacists')
    },
    onError: () => {
      statusMessage.current.show([
        { severity: 'danger', summary: 'Error while reactivating pharmacist.' },
      ])
    },
  })
}

export default useReactivatePharmacist
