import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import CreateSmartFlipDialog from '@components/smartFlips/CreateSmartFlipDialog'

export function UnlinkedSmartFlip({ prescription, prescriptionType }) {
  const messagesRef = useRef()
  const [showDialog, setShowDialog] = useState(false)

  return (
    <div className="flex flex-row align-items-center gap-2">
      <Toast ref={messagesRef} />
      <CreateSmartFlipDialog
        prescription={prescription}
        prescriptionType={prescriptionType}
        isVisible={showDialog}
        messagesRef={messagesRef}
        onHide={() => setShowDialog(false)}
      />
      <div>Unlinked</div>
      <Button
        label="Link"
        className="p-button-text p-button-sm"
        icon="fa-solid fa-link"
        onClick={() => setShowDialog(true)}
        style={{ padding: 0 }}
      />
    </div>
  )
}

export default UnlinkedSmartFlip
