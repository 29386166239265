import React, { useEffect, useRef } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Card } from 'primereact/card'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import DrawersSetup from '@components/medboxes/DrawerSetup'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { confirmDialog } from 'primereact/confirmdialog'
import moment from 'moment'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DoseFillsTable from '@components/doseFills/DoseFillsTable'
import {
  useArchiveMedBoxMutation,
  useDeleteMedBoxMutation,
  useMedBoxQuery,
  useUnarchiveMedBoxMutation,
} from '@hooks/medBoxes'
import { useSite } from '@hooks/sites'
import {
  MED_BOXES_PATH,
  SUPER_ADMIN_ROLE,
  LABELS,
  NOT_APPLICABLE_TEXT,
  MED_BOX_TYPE,
  E_KITS_PATH,
  MED_BOX_STATUS_TAG_COLOR,
  E_KIT_TYPE,
} from './config'

function MedBoxDetailRow({ children, label }) {
  return (
    <div className="flex hover:surface-100 hover:opacity-50 justify-content-between border-bottom-100 p-3 border-bottom-1">
      <div className="w-full font-semibold text-base">
        {label}
        :
      </div>
      <div className="w-full">
        {children}
      </div>
    </div>
  )
}

function MedBox({ useMedBoxesHeader, type }) {
  const { medBoxId } = useParams()
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const user = useCurrentUser()
  const organization = useCurrentOrganization()
  const { setHeader } = useMedBoxesHeader()
  const {
    data: medbox,
    isLoading: medboxIsLoading,
  } = useMedBoxQuery(medBoxId)
  const { data: site, isLoading: siteIsLoading } = useSite(medbox?.siteId)
  const { mutateAsync: deleteMedBox } = useDeleteMedBoxMutation(statusMessage)
  const {
    mutateAsync: archiveMedBox,
    isLoading: archiveMedBoxIsLoading,
  } = useArchiveMedBoxMutation(statusMessage, medBoxId)
  const {
    mutateAsync: unarchiveMedBox,
    isLoading: unarchiveMedBoxIsLoading,
  } = useUnarchiveMedBoxMutation(statusMessage, medBoxId)

  const isSuperAdmin = user.role === SUPER_ADMIN_ROLE
  const basePath = type === MED_BOX_TYPE ? MED_BOXES_PATH : E_KITS_PATH
  const labelText = LABELS[type]

  const accept = async () => {
    await deleteMedBox(medBoxId)
    navigate(`/${basePath}`)
  }

  const handleDeleteMedBox = async () => {
    confirmDialog({
      message: `Are you sure you want to delete this ${labelText}`,
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
    })
  }

  const {
    deviceType,
    label,
    mac,
    organizationId,
    status,
    statusUpdatedAt,
    siteId,
    createdAt,
    simpillDrawers,
    iotResponse,
    patients,
    archivedAt,
  } = medbox || {}

  const handleArchiveUnarchive = async () => {
    if (!medbox) return
    if (archivedAt) {
      await unarchiveMedBox()
    } else {
      await archiveMedBox()
    }
  }

  const primaryAction = (
    <div className="flex gap-2">
      {
        isSuperAdmin && type === E_KIT_TYPE && medbox && (
          <Button
            className="p-button-sm"
            label={archivedAt ? 'Unarchive' : 'Archive'}
            onClick={() => confirmDialog({
              message: archivedAt ? `Are you sure you want to unarchive this ${labelText}?` : `Are you sure you want to archive this ${labelText}?`,
              header: archivedAt ? `Unarchive ${labelText}` : `Archive ${labelText}`,
              icon: 'pi pi-exclamation-triangle',
              accept: handleArchiveUnarchive,
              reject: () => {},
              acceptButtonProps: {
                loading: archivedAt ? unarchiveMedBoxIsLoading : archiveMedBoxIsLoading,
              },
            })}
            loading={archiveMedBoxIsLoading || unarchiveMedBoxIsLoading}
          />
        )
      }
      {
       (isSuperAdmin || type === MED_BOX_TYPE) && (
         <Link to={`/${basePath}/${medBoxId}/edit`}>
           <Button className="p-button-sm" label={`Edit ${labelText}`} />
         </Link>
       )
      }
      {
        isSuperAdmin
      && <Button onClick={handleDeleteMedBox} icon="pi pi-times" className="p-button-danger p-button-sm" label={`Delete ${labelText}`} />
      }
    </div>
  )

  const title = {
    label: (
      <div className="py-2 px-3">
        <span>
          <i className="text-xl pi pi-box mr-2" />
          {medbox?.label}
        </span>
      </div>
    ),
  }

  const breadcrumbs = [
    {
      label: labelText,
      template: <span>{labelText}</span>,
    },
  ]

  useEffect(() => {
    setHeader({
      title,
      tabs: [],
      breadcrumbs,
      primaryAction,
    })
  }, [medbox, medboxIsLoading, archiveMedBoxIsLoading, unarchiveMedBoxIsLoading])

  const formattedStatusUpdatedAt = moment(statusUpdatedAt).format('LL')
  const formattedCreatedAt = createdAt
    ? moment(createdAt).format('LL')
    : NOT_APPLICABLE_TEXT

  const tagColor = MED_BOX_STATUS_TAG_COLOR[status] || NOT_APPLICABLE_TEXT

  // TODO: Do not load page unless the site is loaded and remove reference to organization timezone
  // which should not be used here.
  let timezone
  if (type === E_KIT_TYPE) {
    timezone = siteIsLoading ? organization.timezone : site?.timezone
  } else {
    timezone = patients?.[0]?.timezone
  }

  return (
    <>
      <Toast ref={statusMessage} />
      <div className="flex flex-column gap-2 justify-space-between p-3">
        <div>
          <Card className="shadow-3 p-1">
            <div className="flex justify-space-between align-items-center mb-2">
              <span className="text-base font-semibold">Med Box Details</span>
              {
              type === E_KIT_TYPE && (
                <div className="text-center">
                  <Button
                    label="Medication Assignment and refill"
                    className="text-base font-light"
                    onClick={() => {
                      navigate(`/admin/ekits/${medBoxId}/ekit_configure`)
                    }}
                  />
                </div>
              )
            }
            </div>

            <MedBoxDetailRow label="ID">
              {medboxIsLoading ? <Skeleton className="mb-2" /> : medBoxId}
            </MedBoxDetailRow>
            <MedBoxDetailRow label="Device Type">
              {medboxIsLoading ? <Skeleton className="mb-2" /> : LABELS[deviceType]}
            </MedBoxDetailRow>
            <MedBoxDetailRow label="Label">
              {medboxIsLoading ? <Skeleton className="mb-2" /> : label}
            </MedBoxDetailRow>
            <MedBoxDetailRow label="Mac">
              {medboxIsLoading ? <Skeleton className="mb-2" /> : mac}
            </MedBoxDetailRow>
            <MedBoxDetailRow
              label="Site"
            >
              {
              medboxIsLoading ? <Skeleton className="mb-2" />
                : (
                  <a className="text-blue-500 cursor-pointer" href={`/admin/sites/${siteId}`}>
                    {site?.name}
                  </a>
                )
              }
            </MedBoxDetailRow>
            <MedBoxDetailRow label="Status">
              {
              medboxIsLoading ? <Skeleton className="mb-2" />
                : <Tag style={{ background: tagColor }}>{status}</Tag>
            }
            </MedBoxDetailRow>
            {
              isSuperAdmin && (
                <MedBoxDetailRow label="Archived">
                  {
                  medboxIsLoading ? <Skeleton className="mb-2" />
                    : <Tag style={{ background: archivedAt ? 'red' : 'green' }}>{archivedAt ? 'Yes' : 'No'}</Tag>
                  }
                </MedBoxDetailRow>
              )
            }
            <MedBoxDetailRow
              label="Organization"
            >
              {
              medboxIsLoading ? <Skeleton className="mb-2" />
                : (
                  <a className="text-blue-500 cursor-pointer" href={`/admin/organizations/${organizationId}`}>
                    {organization?.name}
                  </a>
                )
              }
            </MedBoxDetailRow>
            <MedBoxDetailRow label="Patients">
              {
              medboxIsLoading ? <Skeleton className="mb-2" />
                : (
                  <div className="flex flex-column gap-2">
                    {patients?.map((patient) => (
                      <a key={patient.id} className="text-blue-500 cursor-pointer" href={`/admin/patients/${patient.id}`}>
                        <span>{patient.fullName}</span>
                      </a>
                    ))}
                  </div>
                )
              }
            </MedBoxDetailRow>
            <MedBoxDetailRow
              label="Updated At"
            >
              {
              medboxIsLoading ? <Skeleton className="mb-2" />
                : formattedStatusUpdatedAt
            }
            </MedBoxDetailRow>
            <MedBoxDetailRow label="Created At">
              {
              medboxIsLoading ? <Skeleton className="mb-2" />
                : formattedCreatedAt
            }
            </MedBoxDetailRow>

          </Card>
        </div>
        {
          type === E_KIT_TYPE
          && (
          <Card className="shadow-3 p-1">
            {
              medboxIsLoading ? <Skeleton width="10rem" height="4rem" />
                : <DrawersSetup drawers={simpillDrawers} />
            }
          </Card>
          )
        }
        <SuperAdminCard title="IoT Response">
          <InputTextarea autoResize value={JSON.stringify(iotResponse, null, 2)} rows={40} />
        </SuperAdminCard>
        <SuperAdminCard title="Dispensed Fills">
          <DoseFillsTable timezone={timezone} model="Simpill" id={medBoxId} status="all" />
        </SuperAdminCard>
      </div>
    </>
  )
}

export default MedBox
