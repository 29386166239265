import { useMemo } from 'react'
import {
  map, get,
} from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useAdmins(organizationId) {
  const query = useQuery({
    queryKey: ['admins', organizationId],
    queryFn: () => ApiClient.get('/admins', { params: { organizationId } }),
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.orgAdmins'), (admin) => (admin)),
  }), [query.data])
}

export function useDeactivatedAdmins(organizationId) {
  const query = useQuery({
    queryKey: ['deactivatedAdmins', organizationId],
    queryFn: () => ApiClient.get('/admins/deactivated', { params: { organizationId } }),
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.deactivatedSiteAdmins'), (admin) => (admin)),
  }), [query.data])
}

export function useAdmin(adminId) {
  return useQuery({
    queryKey: ['admin', adminId],
    queryFn: () => ApiClient.get(`/admins/${adminId}`),
    enabled: !!adminId,
  })
}

export function useReactivateAdmin(statusMessage, adminLabelSingular) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['reactivateAdmin'],
    mutationFn: ({ userId, note }) => (
      ApiClient.post(`/admins/${userId}/reactivate`, { note })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: `${adminLabelSingular} reactivated!` },
      ])
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries('deactivatedAdmins')
    },
    onError: () => {
      statusMessage.current.show([
        { severity: 'danger', summary: `Error while reactivating ${adminLabelSingular}.` },
      ])
    },
  })
}

export function useCreateAdminMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (admin) => ApiClient.post('/admins', admin),
    onSuccess: () => {
      queryClient.invalidateQueries('admins')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateAdminMutation(adminId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (admin) => ApiClient.put(`/admins/${adminId}`, admin),
    onSuccess: () => {
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries(['admin', adminId])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUserTitleOptions() {
  const query = useQuery({ queryKey: ['userTitleOptions'], queryFn: () => ApiClient.get('/user_titles/options') })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (userTitle) => (userTitle)),
  }))
}

export function useSetUserTitleMutation(adminId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (title) => ApiClient.post(`/users/${adminId}/set_title`, { title }),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin', adminId])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Title updated',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateAdminSettingsMutation(adminId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updateAdminSettings'],
    mutationFn: (adminSettings) => ApiClient.post(`/admins/${adminId}/update_settings`, {
      adminSettings,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin', adminId])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useAdminDeactivateMutation(statusMessage, adminLabelSingular) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['deactivateAdmin'],
    mutationFn: ({ adminId, note }) => (
      ApiClient.post(`/users/${adminId}/deactivate`, { note })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: `${adminLabelSingular} deactivated successfully`,
        },
      ])
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries('deactivatedAdmins')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
