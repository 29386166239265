import React, { useRef, useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { TabView, TabPanel } from 'primereact/tabview'
import { Messages } from 'primereact/messages'
import { Link } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import MenuTab from '@components/display/MenuTab'
import SearchField from '@components/display/Form/SearchField'
import ActivePharmacists from './ActivePharmacists'
import DeactivatedPharmacists from './DeactivatedPharmacists'

function Title({ searchTerm, setSearchTerm, isLoading }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center mt-2">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Pharmacists
      </span>
      <SearchField
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search pharmacists by name"
        isLoading={isLoading}
      />
    </div>
  )
}

const activePanelHeaderTemplate = (options) => (
  <MenuTab
    label="Active"
    active={options.selected}
    onClick={options.onClick}
  />
)

const deactivatedPanelHeaderTemplate = (options) => (
  <MenuTab
    label="Deactivated"
    active={options.selected}
    onClick={options.onClick}
  />
)

const footer = (pharmacistsCount, isFooterDataLoading) => (
  <span className="text-sm ml-1 my-0 py-0">
    {!isFooterDataLoading ? `Pharmacist Count: ${pharmacistsCount}` : ''}
  </span>
)

function PharmacistsTable({ usePharmacistsHeader }) {
  const statusMessageRef = useRef(null)
  const { setHeader, maxDataViewHeight } = usePharmacistsHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const organization = useCurrentOrganization()
  const rootPath = '/admin/pharmacists'

  useEffect(() => {
    const title = {
      label: (
        <Title
          organization={organization}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isLoading={searchLoading}
        />
      ),
    }

    const primaryAction = (
      <Link to={`${rootPath}/create`}>
        <Button className="p-button-sm" label="Create Pharmacist" />
      </Link>
    )

    setHeader({
      rootPath, title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [organization, searchTerm, searchLoading])

  return (
    <div className="col-12">
      <Messages ref={statusMessageRef} />
      <Card className="h-full">
        <TabView panelContainerClassName="py-2 px-0">
          <TabPanel header="Active" headerTemplate={activePanelHeaderTemplate}>
            <ActivePharmacists
              statusMessageRef={statusMessageRef}
              maxDataViewHeight={maxDataViewHeight}
              setSearchLoading={setSearchLoading}
              searchTerm={searchTerm}
              rootPath={rootPath}
              footer={footer}
            />
          </TabPanel>
          <TabPanel header="Deactivated" headerTemplate={deactivatedPanelHeaderTemplate}>
            <DeactivatedPharmacists
              statusMessageRef={statusMessageRef}
              maxDataViewHeight={maxDataViewHeight}
              setSearchLoading={setSearchLoading}
              searchTerm={searchTerm}
              rootPath={rootPath}
              footer={footer}
            />
          </TabPanel>
        </TabView>
      </Card>
    </div>
  )
}

export default PharmacistsTable
