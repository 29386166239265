import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { confirmDialog } from 'primereact/confirmdialog'
import ActionsTable from '@components/conditions/ActionsTable'
import ConditionNote from '@components/conditions/ConditionNote'
import DoseScheduleSelector from './DoseScheduleSelector'
import { useUpdateDoseCondition, useDeleteDoseCondition } from './doseConditionHooks'

import './dose-condition.scss'

function EditDoseConditionDialog({
  doseCondition,
  doseConditionStatusMessage,
  doseSchedules,
  existingDoseConditions,
  onHide,
  isPrn,
  visible,
}) {
  const [actions, setActions] = useState(doseCondition.actions || [])
  const [conditionNote, setConditionNote] = useState(doseCondition.note)
  const [selectedSchedules, setSelectedSchedules] = useState(
    doseCondition.schedules.map((s) => s.id),
  )

  const {
    mutateAsync: updateDoseCondition,
    isLoading: isUpdatingDoseCondition,
  } = useUpdateDoseCondition(doseConditionStatusMessage)
  const {
    mutateAsync: deleteDoseCondition,
    isLoading: isDeletingDoseCondition,
  } = useDeleteDoseCondition(doseConditionStatusMessage)

  const onUpdateDoseCondition = () => {
    updateDoseCondition(
      {
        id: doseCondition.id,
        note: conditionNote,
        scheduleIds: selectedSchedules.map((scheduleId) => parseInt(scheduleId, 10)),
        actions,
      },
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const onDeleteDoseCondition = () => {
    deleteDoseCondition(
      {
        id: doseCondition.id,
      },
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <h3 className="font-semibold text-lg flex flex-column gap-2">
        {doseCondition.conditionName}
      </h3>
      <Button
        icon="pi pi-trash"
        className="p-button-outlined p-button-text"
        type="button"
        loading={isDeletingDoseCondition}
        style={{ color: 'var(--red-500)' }}
        onClick={() => confirmDialog({
          message: 'Are you sure you want to delete this criteria / vitals?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: onDeleteDoseCondition,
        })}
      />
    </div>
  )

  const otherDoseConditionsForType = existingDoseConditions
    .filter((condition) => condition.conditionType === doseCondition.conditionType
      && condition.id !== doseCondition.id)

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
      style={{ width: '75vw' }}
    >
      <div className="flex flex-column gap-4 align-items-center px-2">
        {
          !isPrn && (
            <DoseScheduleSelector
              conditionType={doseCondition.conditionType}
              doseSchedules={doseSchedules}
              otherDoseConditionsForType={otherDoseConditionsForType}
              selectedSchedules={selectedSchedules}
              setSelectedSchedules={setSelectedSchedules}
            />
          )
        }
        <ConditionNote
          conditionNote={conditionNote}
          conditionType={doseCondition.conditionType}
          setConditionNote={setConditionNote}
        />
        <ActionsTable
          actions={actions}
          conditionType={doseCondition.conditionType}
          setActions={setActions}
        />
        <Button
          label="Update"
          className="flex w-full p-button-sm"
          onClick={onUpdateDoseCondition}
          loading={isUpdatingDoseCondition}
        />
      </div>
    </Dialog>
  )
}

export default EditDoseConditionDialog
