/**
 * Find the smart flip object that matches the given from and to prescriptions.
 * @param {Array} smartFlips - a list of smart flip objects
 * @param {Object} fromPrescription - the smart flip's previous prescription
 * @param {Object} toPrescription - the smart flip's next prescription
 * @returns {Object} - the matching smart flip object
 */
// eslint-disable-next-line import/prefer-default-export
export const findSmartFlip = (smartFlips, fromPrescription, toPrescription) => {
  if (!smartFlips || !Array.isArray(smartFlips) || !fromPrescription || !toPrescription) {
    return undefined
  }

  return smartFlips.find(({ deletedAt, fromPrescriptionId, toPrescriptionId }) => !deletedAt
    && fromPrescriptionId === fromPrescription.id && toPrescriptionId === toPrescription.id)
}
