import React, { useRef, useState } from 'react'
import moment from 'moment-timezone'
import { confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import AttributeCard from '@components/display/AttributeCard'
import {
  DoseAttributeRow,
  DateEditor,
  TextAreaEditor,
  EditButton,
  DropdownEditor,
} from '@components/DoseAttributeRow'
import ScheduleEditor from '@components/clientDoses/Schedule/ScheduleEditor/ScheduleEditor'
import { AddTimeEditor } from '@components/pharmacyOrders/Editors'
import { getTaskActionFriendlyName } from '@components/tasks/utils'
import { useCreateSchedule, useDeleteSchedule, useUpdateSchedule } from '@hooks/schedules'
import { useUpdateTask } from '@hooks/tasks'
import { momentFormats, momentToLocalWithoutChangingTime, momentTzWithoutChangingTime } from '@services/utils/moment'
import TaskActionMenu from './TaskActionMenu'

export function TaskTable({ task, editable, timezone }) {
  const statusMessages = useRef(null)
  const [editAttribute, setEditAttribute] = useState(null)
  const {
    mutateAsync: updateTask,
    isLoading,
  } = useUpdateTask({ statusMessage: statusMessages })

  const {
    mutateAsync: createSchedule,
  } = useCreateSchedule(statusMessages)

  const {
    mutateAsync: updateSchedule,
  } = useUpdateSchedule(statusMessages)

  const {
    mutateAsync: deleteSchedule,
  } = useDeleteSchedule(statusMessages)

  if (!task) {
    return null
  }

  const handleUpdateAttribute = (attribute, value) => {
    const payload = { id: task.id }
    payload[attribute] = value

    if (attribute === 'showOnTar' && typeof value === 'object') {
      payload.showOnTar = value?.value
    }

    if (attribute === 'taskAction' && typeof value === 'object') {
      payload.taskAction = value?.value
    }

    updateTask(payload, {
      onSuccess: () => {
        setEditAttribute(null)
      },
    })
  }

  const handleChangeTaskType = async (prn) => handleUpdateAttribute('taskType', prn ? 'prn' : 'routine')

  const handleCreateSchedule = ({ schedule }) => createSchedule({
    schedule: {
      taskId: task.id,
      flexibilityAttributes: schedule.flexibilityAttributes,
      rrule: schedule.rrule,
      time_hh_mm: schedule.time_hh_mm,
    },
  })

  const handleUpdateSchedule = ({ schedule }) => updateSchedule({
    schedule: {
      id: schedule.id,
      flexibilityAttributes: schedule.flexibilityAttributes,
      rrule: schedule.rrule,
      time_hh_mm: schedule.time_hh_mm,
    },
  })

  const handleDeleteSchedule = ({ schedule }) => deleteSchedule({
    scheduleId: schedule.id,
  })

  const schedules = task.schedules?.map((schedule) => ({
    ...schedule,
    // TODO: This is a hack to avoid refactoring a bunch of schedule components. We should remove
    //       this and refactor frontend schedule components after the introduction of TypeScript.
    momentTime: moment().set({
      hour: schedule.timeHours,
      minute: schedule.timeMinutes,
      second: 0,
      millisecond: 0,
    }).valueOf(),
  }))

  const taskAttributes = [
    {
      label: 'Title',
      currentValue: task.title,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'title' ? setEditAttribute(null) : setEditAttribute('title'))}
          isEditing={editAttribute === 'title'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'title'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={task.title}
          setText={(value) => handleUpdateAttribute('title', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'title',
    },
    {
      label: 'Note',
      currentValue: task.note,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'note' ? setEditAttribute(null) : setEditAttribute('note'))}
          isEditing={editAttribute === 'note'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'note'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={task.note}
          setText={(value) => handleUpdateAttribute('note', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'note',
    },
    {
      label: 'Description',
      currentValue: task.description,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'description' ? setEditAttribute(null) : setEditAttribute('description'))}
          isEditing={editAttribute === 'description'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'description'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={task.description}
          setText={(value) => handleUpdateAttribute('description', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'description',
    },
    {
      label: 'Action',
      currentValue: getTaskActionFriendlyName(task.taskAction),
      action: (
        <EditButton
          onClick={editAttribute === 'taskAction' ? () => setEditAttribute(null) : () => setEditAttribute('taskAction')}
          isEditing={editAttribute === 'taskAction'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'taskAction'}
        />
      ),
      editor: (
        <DropdownEditor
          option={task.taskAction}
          optionLabel="label"
          options={[
            { label: getTaskActionFriendlyName('binary'), value: 'binary' },
            { label: getTaskActionFriendlyName('text'), value: 'text' },
            { label: getTaskActionFriendlyName('vital'), value: 'vital' },
          ]}
          setOption={(value) => handleUpdateAttribute('taskAction', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'taskAction',
    },
    {
      label: 'Start Date',
      currentValue: task.startAt && timezone
        ? moment(task.startAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'startAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={task.startAt
            ? momentToLocalWithoutChangingTime(moment(task.startAt), timezone) : null}
          setMomentDate={(value) => {
            const startToSet = momentTzWithoutChangingTime(value.startOf('day'), timezone)
            return handleUpdateAttribute('startAt', startToSet.toISOString())
          }}
          max={task.endAt ? moment(task.endAt) : null}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      currentValue: task.endAt && timezone
        ? moment(task.endAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'endAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={task.endAt
            ? momentToLocalWithoutChangingTime(moment(task.endAt), timezone) : null}
          min={task.startAt ? moment(task.startAt) : null}
          setMomentDate={(value) => {
            if (!value) {
              return handleUpdateAttribute('endAt', null)
            }
            const newEnd = momentTzWithoutChangingTime(value.endOf('day'), timezone)
            if (newEnd.startOf('day').isBefore(moment().startOf('day'))) {
              confirmDialog({
                message: 'The end date is before today, are you sure you want to continue?',
                header: 'Warning',
                accept: () => handleUpdateAttribute('endAt', newEnd.toISOString()),
                icon: 'pi pi-exclamation-triangle',
              })
            } else {
              return handleUpdateAttribute('endAt', newEnd.toISOString())
            }
          }}
          isLoading={isLoading}
          showButtonBar
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
    {
      label: 'Show on TAR',
      currentValue: task.showOnTar ? 'Yes' : 'No',
      action: (
        <EditButton
          onClick={editAttribute === 'showOnTar' ? () => setEditAttribute(null) : () => setEditAttribute('showOnTar')}
          isEditing={editAttribute === 'showOnTar'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'showOnTar'}
        />
      ),
      editor: (
        <DropdownEditor
          option={task.showOnTar}
          optionLabel="label"
          options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
          setOption={(value) => handleUpdateAttribute('showOnTar', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'showOnTar',
    },
    {
      label: 'Schedule',
      display: task.taskType === 'routine',
      currentValue: (
        <ScheduleEditor
          schedules={schedules}
          editable={editable}
          handleUpdateSchedule={handleUpdateSchedule}
          handleDeleteSchedule={handleDeleteSchedule}
        />
      ),
      action: (
        <AddTimeEditor
          handleCreateSchedule={handleCreateSchedule}
          schedulableType="Task"
        />
      ),
    },
  ]

  const actionMenuTemplate = () => editable && (
    <TaskActionMenu
      handleSetPrn={handleChangeTaskType}
      isLoading={isLoading}
      task={task}
    />
  )

  const tags = [{ value: task.taskType, rounded: false, severity: 'info' }]

  return (
    <div className="col-12">
      <Toast ref={statusMessages} />
      <AttributeCard
        ActionComponent={actionMenuTemplate}
        tags={tags}
        title={{ label: 'Task' }}
      >
        <ul className="list-none p-0 m-0 dose-order-fields">
          {taskAttributes
            .filter(({ display = true }) => display)
            .map(({
              label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
            }, index) => (
              <DoseAttributeRow
                key={label}
                label={label}
                valueChangeHandler={valueChangeHandler}
                currentValue={currentValue}
                editable={editable}
                newValue={newValue}
                action={action}
                editor={editor}
                isEditing={isEditing}
                backgroundHighlight={index % 2 === 0}
              />
            ))}
        </ul>
      </AttributeCard>
    </div>
  )
}

export default TaskTable
