import React from 'react'

function HistoryOppositeContent({ content }) {
  return (
    <div className="w-6rem px-3 text-sm font-semibold text-left">
      {content}
    </div>
  )
}

export default HistoryOppositeContent
