import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useDeleteSmartFlip = (statusMessage) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id) => ApiClient.delete(`/smart_flips/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries('prescription')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDeleteSmartFlip
