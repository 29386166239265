import React, { useState, useEffect } from 'react'
import { first, isEmpty } from 'lodash'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'
import { ProfileForm, SiteForm, SettingsForm } from '@components/patients/PatientForm'
import { useCurrentOrganization } from '@components/App'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'

const matchInfoTemplate = (organization, viewMatchCallBack) => (
  <div className="d-flex flex-1 align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="pi pi-info-circle mr-2 text-2xl text-blue-600" />
      <span className="text-base text-blue-600 font-normal">
        {`We found an existing match for this ${organization.patientLabelSingular}!`}
      </span>
    </div>
    <Button
      label="View Match"
      onClick={viewMatchCallBack}
      icon="pi pi-arrow-right"
      iconPos="right"
      className="p-button-outlined p-button-sm h-2rem w-9rem white-space-nowrap"
    />
  </div>
)

function OnboardNewUser({
  pendingUser, handleOnboardPendingUser, statusMessage, onboardUserLoading,
  viewMatchCallBack, patients, isPatientsLoading,
}) {
  const organization = useCurrentOrganization()
  const [assignedSiteIds, setAssignedSiteIds] = useState([])
  const [matchFound, setMatchFound] = useState(false)
  const [settings, setSettings] = useState({
    selfAdmin: false,
    ...(organization.ccmEnabled ? { ccmEligible: false } : {}),
  })

  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    ssn: '',
    firstName: '',
    lastName: '',
    customerId: '',
    roomNumber: '',
    dateOfBirth: '',
    gender: '',
  })

  const {
    data: { sites } = [],
    isLoading: isSitesLoading,
  } = useSitesQuery({ statusMessage, organizationId: organization.id })

  const handleConfirmUserProfile = () => {
    handleOnboardPendingUser({ ...profile, siteId: first(assignedSiteIds), ...settings })
  }

  const handleUpdateSettings = (setting) => {
    setSettings((s) => ({ ...s, ...setting }))
  }

  useEffect(() => {
    if (isPatientsLoading || onboardUserLoading) return

    setMatchFound(patients.some((element) => element.matchScore > 2))
  }, [patients, isPatientsLoading, onboardUserLoading])

  useEffect(() => {
    if (pendingUser) {
      setProfile({
        firstName: pendingUser.firstName,
        lastName: pendingUser.lastName,
        ssn: pendingUser.ssn,
        roomNumber: pendingUser.roomNumber,
        dateOfBirth: pendingUser.dateOfBirth,
        birthSex: pendingUser.birthSex,
      })
    }
  }, [pendingUser])

  return (
    <div className="col-8 xl:col-6">
      {matchFound && (
        <Message
          className="border-primary w-full justify-content-start mb-3 custom-message-success"
          severity="info"
          content={matchInfoTemplate(organization, viewMatchCallBack)}
        />
      )}
      <ProfileForm
        profile={profile}
        setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
      />
      <div className="field">
        <label className="block">Site*</label>
        <SiteForm
          sites={sites}
          selectedSites={assignedSiteIds}
          setSelectedSites={(siteIds) => setAssignedSiteIds(siteIds)}
          isSitesLoading={isSitesLoading}
        />
      </div>
      <div className="field">
        <label className="block">Settings</label>
        <SettingsForm
          settings={settings}
          setSettings={handleUpdateSettings}
          ccmEnabled={organization.ccmEnabled}
        />
      </div>
      <Button
        label="Save Profile and Onboard User"
        className="p-button-sm mt-1"
        disabled={isEmpty(assignedSiteIds)}
        onClick={handleConfirmUserProfile}
        loading={onboardUserLoading}
      />
    </div>
  )
}

export default OnboardNewUser
