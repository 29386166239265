import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Tag } from 'primereact/tag'
import { Column } from 'primereact/column'
import {
  every, get, isEmpty,
} from 'lodash'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { timeTemplate, titleCase, dateTimeTemplateFromMoment } from '@services/utils'
import moment from 'moment'
import { useUpdateDoseSigMutation } from '@services/hooks/doseSigsHooks'
import { momentFormats, momentFromIso8601Str } from '@services/utils/moment'
import { useHrstDoseSigsQuery, useSubmitMedicationsMutation } from '@hooks/hrst'
import { formatAdministrationAmount, medicineDisplayNameWithStrength } from '@components/clientDoses/doseUtils'
import { useCreateMedicalConditionMutation } from '@hooks/medicalConditions'
import { useNavigate } from 'react-router-dom'
import DoseFrequencyField from './DoseFrequencyField'
import DosePurposeField from './DosePurposeField'
import DoseDiagnosisField from './DoseDiagnosisField'
import DoseEditActions from './DoseEditActions'
import DoseMedicalConditionDialog from './DoseMedicalConditionDialog'

function HRSTDoses({ patient, handleSetHeader }) {
  const statusMessageRef = useRef()
  const {
    data: doseSigs,
    isLoading,
  } = useHrstDoseSigsQuery({
    patientId: patient?.id,
    statusMessage: statusMessageRef,
  })
  const {
    mutateAsync: updateDoseSig,
    isLoading: isEditLoading,
  } = useUpdateDoseSigMutation()

  const {
    mutateAsync: submitMedications,
    isLoading: submitMedicationsLoading,
  } = useSubmitMedicationsMutation(statusMessageRef, patient?.id)

  const navigate = useNavigate()

  const [editingSig, setEditingSig] = useState()
  const [newHrstPurpose, setNewHrstPurpose] = useState()
  const [newHrstFrequency, setNewHrstFrequency] = useState()
  const [newHrstOtherPurpose, setNewHrstOtherPurpose] = useState()
  const [newDiagnosis, setNewDiagnosis] = useState()
  const [isCreationDialogVisible, setIsCreationDialogVisible] = useState(false)

  const {
    mutateAsync: createMedicalCondition,
    isLoading: isCreateMedicalConditionLoading,
  } = useCreateMedicalConditionMutation({
    patientId: patient?.id,
    doseId: editingSig?.dose?.id,
    statusMessage: statusMessageRef,
  })

  const onHRSTEdit = (text) => {
    setNewHrstOtherPurpose(text)
  }

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [patient])

  if (!patient) return null

  const medicineTemplate = ({
    dose: {
      orderText, medicine, id, patientId,
    },
  }) => {
    const handleMedicationSelection = ({ doseId }) => {
      navigate(`/admin/patients/${patientId}/doses/manage/${doseId}`)
    }
    return (
      <div className="flex flex-column gap-2 cursor-pointer hover:underline" onClick={() => handleMedicationSelection({ doseId: id })}>
        {isEmpty(orderText)
          ? (
            <span style={{ wordBreak: 'break-all' }}>{medicineDisplayNameWithStrength({ orderText, medicine })}</span>
          )
          : <span style={{ wordBreak: 'break-all' }}>{orderText}</span>}

      </div>
    )
  }

  const schedulesTemplate = ({ prn, schedules }) => {
    if (prn) {
      return (
        <Tag style={{ background: prn ? 'var(--green-400)' : 'var(--bluegray-200)' }}>
          <span className="text-xs font-normal">PRN</span>
        </Tag>
      )
    }
    return (
      <div className="flex flex-column gap-2">
        {schedules.map((schedule) => (
          <span key={schedule} className="text-xs">
            {titleCase(schedule.text)}
            {' '}
            at
            {' '}
            {timeTemplate(schedule.time)}
          </span>
        ))}
      </div>
    )
  }

  const purposeTemplate = ({ id, hrstPurpose, hrstOtherPurpose }) => (
    <DosePurposeField
      purpose={hrstPurpose}
      hrstOtherPurpose={hrstOtherPurpose}
      newPurpose={newHrstPurpose}
      setNewPurpose={setNewHrstPurpose}
      newOtherPurpose={newHrstOtherPurpose}
      setNewOtherPurpose={onHRSTEdit}
      isEditing={editingSig?.id === id}
      isEditLoading={isEditLoading}
      doseSigs={doseSigs}
    />
  )

  const frequencyTemplate = (doseSig) => {
    const {
      id, hrstFrequency, hl7ScheduleCodeDescriptions,
    } = doseSig

    const administrationAmountDisplay = formatAdministrationAmount({
      administrationAmount: doseSig.administrationAmount,
      medicine: doseSig.dose.medicine,
    })
    return (
      <div className="flex flex-column gap-2">
        <DoseFrequencyField
          frequency={hrstFrequency}
          newFrequency={newHrstFrequency}
          setNewFrequency={setNewHrstFrequency}
          hl7ScheduleCodeFrequency={get(hl7ScheduleCodeDescriptions, '[0]')}
          isEditing={editingSig?.id === id}
          isEditLoading={isEditLoading}
        />
        <span>{administrationAmountDisplay}</span>
      </div>
    )
  }

  const diagnosisTemplate = (doseSig) => (
    <DoseDiagnosisField
      doseSig={doseSig}
      editingSig={editingSig}
      isEditing={editingSig?.dose.id === doseSig.dose.id}
      newDiagnosis={newDiagnosis}
      setNewDiagnosis={setNewDiagnosis}
      setIsCreationDialogVisible={setIsCreationDialogVisible}
    />
  )

  const editTemplate = (doseSig) => (
    <DoseEditActions
      doseSig={doseSig}
      editingSig={editingSig}
      loading={isEditLoading}
      onEdit={() => {
        setEditingSig(doseSig)
        setNewHrstPurpose(doseSig.hrstPurpose)
        setNewHrstFrequency(doseSig.hrstFrequency)
        setNewHrstOtherPurpose(doseSig.hrstOtherPurpose)
        setNewDiagnosis(doseSig.dose.hrstMedicalConditionId)
      }}
      onEditCancel={() => setEditingSig(null)}
      onEditSave={async () => {
        await updateDoseSig({
          doseSig: {
            id: doseSig.id,
            hrstPurpose: newHrstPurpose,
            hrstFrequency: newHrstFrequency,
            hrstOtherPurpose: newHrstOtherPurpose,
            dose: {
              hrstMedicalConditionId: newDiagnosis,
            },
          },
        })
        setEditingSig(null)
      }}
    />
  )

  const detailsTemplate = (doseSig) => {
    const tag = { value: doseSig.prn ? 'PRN' : 'Schedule', rounded: false, severity: 'info' }
    const startAt = momentFromIso8601Str(doseSig.startAtWall)
    const endAt = momentFromIso8601Str(doseSig.endAtWall)
    return (
      <div className="flex flex-column gap-2">
        <div className="flex flex-row gap-2">
          <Tag {...tag} className="w-4rem" />
          {doseSig.dose.discontinued && <Tag className="w-4rem" value="Ended" rounded={false} style={{ background: 'var(--bluegray-200)' }} />}
        </div>
        {doseSig.instructions && <span>{doseSig.instructions}</span>}
        { startAt?.isValid() && (
        <span>
          Start:
          {' '}
          {startAt.format(momentFormats.dateYear)}
        </span>
        ) }
        { endAt?.isValid() && (
        <span>
          End:
          {' '}
          {endAt.format(momentFormats.dateYear)}
        </span>
        ) }
      </div>
    )
  }

  const everySigHasNoPurpose = every(doseSigs, (doseSig) => !doseSig.hrstPurpose)
  const sigsWithoutPurpose = doseSigs.filter((doseSig) => !doseSig.hrstPurpose)

  return (
    <div className="col-12 flex flex-column gap-2">
      <Messages ref={statusMessageRef} />
      <DoseMedicalConditionDialog
        isVisible={isCreationDialogVisible}
        onHide={() => setIsCreationDialogVisible(false)}
        onSave={createMedicalCondition}
        isLoading={isCreateMedicalConditionLoading}
        doseId={editingSig?.dose?.id}
        patientId={patient?.id}
      />
      <div className="flex flex-row gap-2 justify-content-between align-items-center">
        <span className="text-lg font-bold">Doses</span>
        {
          patient.hrstParticipant && (
          <div className="flex flex-column align-items-center justify-content-center">
            <Button
              label="Update in HRST"
              className="p-button-sm p-button-text"
              icon="pi pi-refresh"
              onClick={submitMedications}
              loading={submitMedicationsLoading}
              disabled={everySigHasNoPurpose}
            />
            <span className="text-xs">
              Updated:
              {' '}
              {get(patient, 'hrstParticipant.medicationsUpdatedAt') ? dateTimeTemplateFromMoment(moment(get(patient, 'hrstParticipant.medicationsUpdatedAt'))) : 'Never'}
            </span>
            {
              !get(patient, 'hrstParticipant.isSynced') && (
                <span className="text-xs text-orange-500">There are new changes since last update</span>
              )
            }
            {
              !isLoading && everySigHasNoPurpose && (
              <span className="text-xs text-orange-500">Please set a purpose for at least one medication</span>
              )
            }
            {
              !isLoading && !everySigHasNoPurpose && sigsWithoutPurpose.length > 0 && (
                <span className="text-xs">
                  {sigsWithoutPurpose.length}
                  {' '}
                  Medication
                  {sigsWithoutPurpose.length > 1 ? 's' : ''}
                  {' '}
                  will be skipped due to missing purpose
                </span>
              )
            }
          </div>
          )
        }

      </div>
      <DataTable
        value={doseSigs}
        loading={isLoading}
        // disabling row grouping temporarily to find solution for primereact scroll bug
        // rowGroupMode="rowspan"
        // groupRowsBy={['dose.id']}
      >
        <Column
          field="dose.id"
          header="Medication"
          body={medicineTemplate}
          style={{ minWidth: '150px', maxWidth: '150px' }}

        />
        <Column
          field="dose.id"
          header="Diagnosis"
          body={diagnosisTemplate}
          style={{ minWidth: '200px', maxWidth: '200px' }}
        />
        <Column
          header="Details"
          body={detailsTemplate}
          style={{ minWidth: '170px', maxWidth: '170px' }}
        />
        <Column
          header="Schedules"
          body={schedulesTemplate}
          style={{ minWidth: '200px', maxWidth: '200px' }}
        />
        <Column
          header="Frequency"
          body={(rowData) => frequencyTemplate(rowData)}
        />
        <Column
          field="purpose"
          header="Purpose"
          body={(rowData) => purposeTemplate(rowData)}
        />
        <Column
          header=""
          body={(rowData) => editTemplate(rowData)}
          style={{ minWidth: '100px', maxWidth: '100px' }}

        />
      </DataTable>
    </div>
  )
}

export default HRSTDoses
