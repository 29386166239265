import React from 'react'

function HistoryContent({ title, subtitles }) {
  return (
    <div>
      {title && <div className="text-sm font-normal">{title}</div>}
      {subtitles?.map((subtitle) => (
        <div className="text-xs font-normal" key={subtitle}>{subtitle}</div>
      ))}
    </div>
  )
}

export default HistoryContent
