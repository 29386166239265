import { useMemo } from 'react'
import { map, get } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  errorMessageFromError, displayErrorMessages,
} from '@services/errorHandler'

export function useCaregivers(organizationId, currentUser) {
  const query = useQuery(
    {
      queryKey: ['caregivers', organizationId],
      queryFn: () => ApiClient.get(`/caregivers?organization_id=${organizationId}`),
      enabled: currentUser.role !== 'caregiver',
    },
  )

  const data = useMemo(() => {
    if (!query.data) return []
    return query.data.caregivers.map((caregiver) => ({ ...caregiver }))
  }, [query.data])

  const isLoading = useMemo(() => query.isLoading && currentUser.role
    !== 'caregiver', [currentUser.role, query.isLoading])

  return { data, isLoading }
}

export function useCaregiver(caregiverId, organizationId) {
  return useQuery({
    queryKey: ['caregiver', caregiverId, organizationId],
    queryFn: () => {
      let url = `/caregivers/${caregiverId}`
      if (organizationId) {
        url = `${url}?organization_id=${organizationId}`
      }
      return ApiClient.get(url)
    },
    enabled: !!caregiverId,
  })
}

export function useCaregiverConsumptionHistory(caregiverId) {
  const query = useQuery({ queryKey: ['consumptionHistory', caregiverId], queryFn: () => ApiClient.get(`/historical_consumptions?caregiver_id=${caregiverId}`) })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (consumption) => (consumption)),
  }), [query.data])
}

export function useCreateCaregiverMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (caregiver) => ApiClient.post('/caregivers', caregiver),
    onSuccess: () => {
      queryClient.invalidateQueries('caregivers')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateCaregiverMutation(caregiverId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (caregiver) => ApiClient.put(`/caregivers/${caregiverId}`, caregiver),
    onSuccess: () => {
      queryClient.invalidateQueries('caregivers')
      queryClient.invalidateQueries(['caregiver', caregiverId])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUserTitleOptions() {
  const query = useQuery({ queryKey: ['userTitleOptions'], queryFn: () => ApiClient.get('/user_titles/options') })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (userTitle) => (userTitle)),
  }))
}

export function useSetUserTitleMutation(caregiverId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (title) => ApiClient.post(`/users/${caregiverId}/set_title`, { title }),
    onSuccess: () => {
      queryClient.invalidateQueries(['caregiver', caregiverId])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Title updated',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useCaregiverDeactivateMutation(statusMessage, caregiverLabelSingular) {
  return useMutation({
    mutationKey: ['deactivateCaregiver'],
    mutationFn: ({ caregiverId, note }) => (
      ApiClient.post(`/users/${caregiverId}/deactivate`, { note })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: `${caregiverLabelSingular} deactivated successfully`,
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useCaregiverSetPinMutation(caregiverId, statusMessage) {
  return useMutation({
    mutationKey: ['setCaregiverPin', caregiverId],
    mutationFn: ({ pin }) => ApiClient.post(`/caregivers/${caregiverId}/set_temp_pin`, { pin }),
    onError: (error) => {
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}
