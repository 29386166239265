import React from 'react'
import { confirmDialog } from 'primereact/confirmdialog'
import { conditionTypes } from '@components/conditions/config'
import { useDeleteMeasurement } from '@hooks/measurements'
import ConditionCheckInput from './ConditionChecks/ConditionCheckInput'

function ConditionCheckSection({
  mode,
  statusMessage,
  patientId,
  conditions = [],
  conditionChecks = [],
  setConditionChecks,
  visible = true,
}) {
  const {
    mutateAsync: deleteMeasurement,
  } = useDeleteMeasurement({ mode, patientId, statusMessage })

  if (!conditions || !conditions.length || !visible) {
    return null
  }

  const createConditionCheckInput = (condition) => {
    const conditionCheck = conditionChecks.find((cc) => cc.condition.id === condition.id)
      || { condition }

    const conditionType = conditionTypes.find((ct) => ct.code === condition?.conditionType)
    conditionCheck[conditionType.measurementKey] ||= {}
    conditionCheck[conditionType.measurementKey].unit ||= conditionType.units[0]

    return {
      condition,
      conditionCheck,
      key: condition.id,
    }
  }

  const conditionCheckInputs = conditions.map((condition) => createConditionCheckInput(condition))

  const setConditionCheck = (newConditionCheck) => {
    const existingIndex = conditionChecks.findIndex(
      (cc) => cc.condition.id === newConditionCheck.condition.id,
    )

    let updatedConditionChecks

    if (existingIndex !== -1) {
      // A condition check already exists, so we'll replace it with the updated check
      updatedConditionChecks = conditionChecks.map(
        (cc) => (cc.condition.id === newConditionCheck.condition.id
          ? newConditionCheck
          : cc
        ),
      )
    } else {
      // A condition check doesn't exist yet, so we'll add it to the list in the correct order.
      // This scenario happens when creating PRNs that don't have any existing condition checks.
      // In the future, it will also happen if we stop generating condition checks.
      updatedConditionChecks = [...conditionChecks, newConditionCheck]
      updatedConditionChecks.sort((a, b) => {
        const indexA = conditions.findIndex((condition) => condition.id === a.condition.id)
        const indexB = conditions.findIndex((condition) => condition.id === b.condition.id)
        return indexA - indexB
      })
    }

    setConditionChecks(updatedConditionChecks)
  }

  const measurementDeleteDialog = (measurementName, handleDelete) => {
    confirmDialog({
      message: `Do you want to delete this ${measurementName} measurement?`,
      header: 'Confirm Measurement Deletion',
      icon: 'pi pi-exclamation-triangle',
      accept: () => handleDelete(),
    })
  }

  return (
    <div className="flex flex-column gap-2">
      <div>Criteria Checks</div>
      {
        conditionCheckInputs.map(({ condition, conditionCheck, key }) => (
          <ConditionCheckInput
            key={key}
            condition={condition}
            conditionCheck={conditionCheck}
            setConditionCheck={setConditionCheck}
            createConditionCheckInput={createConditionCheckInput}
            deleteMeasurement={deleteMeasurement}
            measurementDeleteDialog={measurementDeleteDialog}
          />
        ))
      }
    </div>
  )
}

export default ConditionCheckSection
